var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "sipActiveCall-Modal TransferCallModal center-zoomIn-transition",
    attrs: {
      "transition": !_vm.getIsMobile ? 'center-zoomIn-transition ' : '',
      "name": _vm.modalName,
      "scrollable": false
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray"
  }, [_c('div', {
    staticClass: "dialer-call-notification"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner w-100 justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-main-text"
  }, [_vm._v("Transfer call")]), _c('div', {
    staticClass: "dialer-call-notification-sub-text"
  }, [_vm._v("Send your call to another user")])]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "dialer-call-notification-actions"
  }, [_c('div', {
    staticClass: "dialer-call-notification-actions-inner"
  }, [_c('b-form', {
    staticClass: "dialer-searchBox",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search here"
    },
    on: {
      "focus": function ($event) {
        _vm.isInputFocuse = true;
      }
    },
    model: {
      value: _vm.form.number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isInputFocuse && !_vm.isEmpty(_vm.searchNumber),
      expression: "isInputFocuse && !isEmpty(searchNumber)"
    }],
    staticClass: "dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down"
  }, [_c('div', {
    staticClass: "dropdown"
  }, [_c('ul', {
    staticClass: "dropdown-menu show"
  }, [_vm._l(_vm.searchNumber, function (data) {
    return [data ? [data.user ? [_c('li', {
      key: data.voipaccount,
      on: {
        "click": function ($event) {
          _vm.form.number = data.extn;
          _vm.isInputFocuse = false;
        }
      }
    }, [_c('a', {
      staticClass: "dropdown-item dialer_contact"
    }, [_c('span', {
      staticClass: "user-profile"
    }, [_c('vb-avatar', {
      attrs: {
        "id": data.voipaccount,
        "image": data.profile_img
      }
    })], 1), _c('div', {
      staticClass: "user-info"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'user.display_name')))]), _c('span', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(data.extn))])])])])] : data.object === 'UserSpeedDial' ? [_c('li', {
      key: data.id,
      on: {
        "click": function ($event) {
          _vm.form.number = data.fullNumber;
          _vm.isInputFocuse = false;
        }
      }
    }, [_c('a', {
      staticClass: "dropdown-item dialer_contact"
    }, [_c('span', {
      staticClass: "user-profile"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm.local_filess.images.user_male,
        "name": data.name
      }
    })], 1), _c('div', {
      staticClass: "user-info"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_vm._v(_vm._s(data.name))]), _c('span', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(data.fullNumber))])])])])] : data.object === 'PhoneBook' ? [_c('li', {
      key: data.id,
      on: {
        "click": function ($event) {
          _vm.form.number = _vm.getProperty(data, 'phones[0].number', _vm.number);
          _vm.isInputFocuse = false;
        }
      }
    }, [_c('a', {
      staticClass: "dropdown-item dialer_contact"
    }, [_c('span', {
      staticClass: "user-profile"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm.local_filess.images.user_male,
        "name": data.emails
      }
    })], 1), _c('div', {
      staticClass: "user-info"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_vm._v(_vm._s(`${data.firstName} ${data.lastName}`))]), _c('span', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, "phones[0].number", "")))])])])])] : [_c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: false,
        expression: "false"
      }],
      key: data.id.toString()
    })]] : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "dialer-searchBox mt-0"
  }, [_c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-headers"
  }, _vm._l(_vm.tabs, function (tab, key) {
    return _c('div', {
      key: key,
      class: `dialer-tab text-capitalize ${_vm.filter.transfer.selected_tab === tab ? 'selected' : ''}`,
      on: {
        "click": function ($event) {
          _vm.filter.transfer.selected_tab = tab;
        }
      }
    }, [_vm._v(" " + _vm._s(tab) + " ")]);
  }), 0), _c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', [_c('ul', {
    staticClass: "list-unstyled dialer-call-transfer-picker mb-0 allow_scroll"
  }, _vm._l(_vm.searchedTransferCall, function (item, key) {
    return _c('li', {
      key: key,
      on: {
        "click": function ($event) {
          return _vm.onSelect(item);
        }
      }
    }, [item.object === 'Teams' ? _c('div', {
      staticClass: "dialer-transfer-info"
    }, [_c('Info', {
      attrs: {
        "id": item.real_id,
        "image": _vm._f("get_property")(item, 'avatar.image.image'),
        "name": item.name,
        "sub_info": _vm._f("get_property")(item, 'ringroup.extn')
      }
    })], 1) : item.object === 'GlobalSpeedDial' ? _c('div', {
      staticClass: "dialer-transfer-info"
    }, [_c('Info', {
      attrs: {
        "image": _vm._f("get_property")(item, 'avatar.image.image'),
        "name": item.name,
        "sub_info": _vm._f("get_property")(item, 'fullNumber')
      }
    })], 1) : item.object === 'UserSpeedDial' ? _c('div', {
      staticClass: "dialer-transfer-info"
    }, [_c('Info', {
      attrs: {
        "image": _vm._f("get_property")(item, 'avatar.image.image'),
        "name": item.name,
        "sub_info": _vm._f("get_property")(item, 'fullNumber')
      }
    })], 1) : !item.object && item.callRecording ? _c('div', {
      staticClass: "dialer-transfer-info"
    }, [_c('Info', {
      attrs: {
        "id": item.voipaccount,
        "image": item.profile_img,
        "name": _vm._f("get_property")(item, 'user.display_name'),
        "sub_info": item.extn
      }
    })], 1) : !item.object && item.label ? _c('div', {
      staticClass: "dialer-transfer-info"
    }, [_c('Info', {
      attrs: {
        "image": _vm._f("get_property")(item, 'avatar.image.image'),
        "name": item.label
      }
    })], 1) : _vm._e()]);
  }), 0)])])])])], 1), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    class: `d-flex dialer-button md-mar-top dialer-button-primary ${!_vm.isValidNumber ? 'disabled' : ''}`,
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.transfer('soft');
      }
    }
  }, [_vm._v(" Soft Transfer ")]), _c('button', {
    class: `d-flex dialer-button md-mar-top dialer-button-primary ${!_vm.isValidNumber ? 'disabled' : ''}`,
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.transfer('hard');
      }
    }
  }, [_vm._v(" Hard Transfer ")]), _c('button', {
    class: `d-flex dialer-button md-mar-top dialer-button-primary ${!_vm.isValidNumber ? 'disabled' : ''}`,
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.testTransfer();
      }
    }
  }, [_vm._v(" Blind Transfer AMI ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }