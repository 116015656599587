<template>
  <div class="crm-homeContainer">
    <!-- <div class="crm-home-leftSideBar">
      <h2 class="crm-menu-title">Menu Items</h2>
      <div class="crm-home-leftSideBar-inner">
        <ul class="list-unstyled"> 
          <li @click="selectTab = 'Insights'" :class="`crm-home-leftSideBar-item ${selectTab == 'Insights' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Insights
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Deals'" :class="`crm-home-leftSideBar-item ${selectTab == 'Deals' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Deals
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Activities'" :class="`crm-home-leftSideBar-item ${selectTab == 'Activities' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Activities
              </span>
            </a>
          </li>
          <li @click="selectTab = 'inbox'" :class="`crm-home-leftSideBar-item ${selectTab == 'inbox' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Inbox
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Documents'" :class="`crm-home-leftSideBar-item ${selectTab == 'Documents' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Documents
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Contacts'" :class="`crm-home-leftSideBar-item ${selectTab == 'Contacts' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Contacts
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Companies'" :class="`crm-home-leftSideBar-item ${selectTab == 'Companies' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Companies
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Products'" :class="`crm-home-leftSideBar-item ${selectTab == 'Products' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class=""><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"></path></svg>
              </span>
              <span class="leftSideBar-item-text">
                Products
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="crm-home-main">
      <div class="crm-home-main-inner">
        <!-- <Insights v-if="selectTab == 'Insights'"/>
        <Deals v-if="selectTab == 'Deals'"/>
        <Activities v-if="selectTab == 'Activities'"/>
        <Documents v-if="selectTab == 'Documents'"/>
        <Contacts v-if="selectTab == 'Contacts'"/>
        <Companies v-if="selectTab == 'Companies'"/>
        <Products v-if="selectTab == 'Products'"/> -->
        <Insights v-if="activeTab === 'crm-insights'" ref="crm-insights"/>
        <Deals v-if="activeTab === 'crm-deals'" ref="crm-deals"/>
        <Activities v-if="activeTab === 'crm-activities'" ref="crm-activities"/>
        <Documents v-if="activeTab === 'crm-documents'" ref="crm-documents"/>
        <Contacts v-if="activeTab === 'crm-contacts'" ref="crm-contacts"/>
        <Companies v-if="activeTab === 'crm-companies'" ref="crm-companies"/>
        <Products v-if="activeTab === 'crm-products'" ref="crm-products"/>
      </div>
    </div>
  </div>
</template>

<script>
import Insights from '@/components/CRM/Insights.vue'
import Deals from '@/components/CRM/Deals.vue'
import Activities from '@/components/CRM/Activities.vue'
import Documents from '@/components/CRM/Documents.vue'
import Contacts from '@/components/CRM/Contacts.vue'
import Companies from '@/components/CRM/Companies.vue'
import Products from '@/components/CRM/Products.vue'

export default {
  name: 'CrmHome',
  components: {
    Insights,
    Deals,
    Activities,
    Documents,
    Contacts,
    Companies,
    Products,

  },
  data(){
    return {
      selectTab: 'Insights'
    }
  },
  computed: {
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>