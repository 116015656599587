<template>
  <tr>
    <td class="three">
      <span class="display-responsive mr-2">TEST :</span>
      <div class="d-flex flex-column">
        <div v-if="data.status === 'Received Call'">
          <Info :only_name="true" v-if="data.dstExt" :id="data.dstExt" /> <b>{{data.dstName | number_formater}}</b> received call from <Info :only_name="true" v-if="data.srcExt" :id="data.srcExt" /> <b>{{data.srcName | number_formater}}</b>
        </div>
        <div v-else-if="data.status === 'Missed Call'">
          <Info :only_name="true" v-if="data.srcExt" :id="data.srcExt" /> <b>{{data.srcName | number_formater}}</b> call missed from <Info :only_name="true" v-if="data.dstExt" :id="data.dstExt" /> <b>{{data.dstName | number_formater}}</b>
        </div>
        <div v-else>
          <Info :only_name="true" v-if="data.srcExt" :id="data.srcExt" /> <b>{{data.srcName | number_formater}}</b> called <Info :only_name="true" v-if="data.dstExt" :id="data.dstExt" /> <b>{{data.dstName | number_formater}}</b>
        </div>
      </div>
    </td>
    <td class="wd-75 four">
      <span class="display-responsive mr-2">TIME & DURATION :</span>
      <span class="time block">
        <i class="fa fa-clock"></i>
        {{ data.date | filterTimeAndDate }}
      </span>
    </td>
    <td class="wd-33-33 five">
      <span class="display-responsive mr-2">Ring Time :</span>
      <span :id="`RingTime-${index}`" v-b-popover.hover.top="`${data.ringSec}`" :data-tooltip="'Duration: ' + data.duration"> 
        <span class="hour">
          <i class="fas fa-hourglass-start"></i>
          {{ data.billSec }}
        </span>
      </span>
    </td>
    <td class="call_history analyticsReportAudio wd-100 six" v-if="true"> 
      <span class="display-responsive mr-2">Call Recorded :</span>
      <div class="d-flex align-items-center justify-content-end">
        <template v-if="data.soundFile">
          <div 
            :id="`ReportingCallRecording-${index}`" 
            v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
            :class="['d-flex align-items-center' , !getUserPermissions.call_recording ? 'for-disable' : '']"
          >
            <vb-audio-player :design="'minimum'"
              :class="!getUserPermissions.call_recording ? 'for-disable' : ''" 
              :disabled="!getUserPermissions.call_recording" 
              :src="data.soundFile" 
            />
            <vb-icon 
              icon="callAnalytics-reporting-download-icon" 
              class="download_icon ml-3" 
              height="17px" 
              width="17px" 
              style="cursor: pointer;" 
              @click="!getUserPermissions.call_recording ? '' : download()" 
              variant="primary"
            />
            <vb-icon style="cursor: pointer;" icon="ai-brain-icon" class="ml-3" width="18.184px" height="17.403px" 
              @click="!getUserPermissions.call_recording ? '' : $emit('transcript-click')"
            />
            <!-- <vb-icon 
              v-if="data.soundFile && data.uniqueid"
              icon="callAnalytics-reporting-download-icon" 
              class="download_icon" 
              height="17px" 
              width="17px" 
              style="cursor: pointer;" 
              @click="!getUserPermissions.call_recording ? '' : $emit('transcript-click')" 
              variant="primary"
            /> -->
          </div>
        </template>
      </div>
    </td>
    <td v-else>-</td>
  </tr>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
export default {
  components: { 
    Info 
  },
  name: 'ReportItem',
  props: {
    data: {
      type: Object,
      default: ()=>({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getVoipUsersAlises: 'getVoipUsersAlises',
    }),
    note() {
      if (!this.data.notes) return "";
      const [note] = this.data.notes.filter((item) => item.log !== null && item.type === "note");
      return note ? note.log : "";
    },
    tags() {
      if (!this.data.notes) return [];
      const tags = this.data.notes.filter((item) => item.type === "tag");
      return tags.filter((tag) => tag.tag && tag.tag.id);
    },
    maptags() {
      const tags = this.tags
      return tags.map((tag) => tag.tag.tag).join('\n');
    },
  },
  filters: {
    filterTimeAndDate(value){
      return moment.unix(value)?.format?.('hh:mm:ss a , MMMM DD YYYY') ?? value
    },
  },
  methods: {
    download() {
      let url = this.data.soundFile || ''
      fetch(url).then(response => response.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          // console.log('blobUrl',blobUrl)
          link.href = blobUrl;
          link.target = '_blank';
          link.download = 'download';
          document.body.appendChild(link);
          link.click();
          link.remove();
          // console.log('runned')
        })
        // .catch(e => console.error(e));

    },
  },
}
</script>

<style>

</style>