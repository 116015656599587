var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-homeContainer"
  }, [_c('div', {
    staticClass: "crm-home-main"
  }, [_c('div', {
    staticClass: "crm-home-main-inner"
  }, [_vm.activeTab === 'crm-insights' ? _c('Insights', {
    ref: "crm-insights"
  }) : _vm._e(), _vm.activeTab === 'crm-deals' ? _c('Deals', {
    ref: "crm-deals"
  }) : _vm._e(), _vm.activeTab === 'crm-activities' ? _c('Activities', {
    ref: "crm-activities"
  }) : _vm._e(), _vm.activeTab === 'crm-documents' ? _c('Documents', {
    ref: "crm-documents"
  }) : _vm._e(), _vm.activeTab === 'crm-contacts' ? _c('Contacts', {
    ref: "crm-contacts"
  }) : _vm._e(), _vm.activeTab === 'crm-companies' ? _c('Companies', {
    ref: "crm-companies"
  }) : _vm._e(), _vm.activeTab === 'crm-products' ? _c('Products', {
    ref: "crm-products"
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }