<template>
  <div class="crm-main-section ActivitySection">
    <TopBar :title="'Activities'">
      <button class="newButton" @click="$modal.show(`${_uid}-CreateActivity`)">
        <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
        <span class="newButton-textPart position-relative d-flex justify-content-center">Create Activities</span>
      </button>
      <div class="crm-main-dd dropdownVersion filterDD ml-16px">
        <b-dropdown no-caret class="">
          <template #button-content>
            <b-icon icon="three-dots-vertical" />
          </template>
          <b-dropdown-item>Import</b-dropdown-item>
          <b-dropdown-item @click="$modal.show(`${_uid}-export`)">Export</b-dropdown-item>
          <b-dropdown-item>Trashed</b-dropdown-item>
          <b-dropdown-item>List Settings</b-dropdown-item>
        </b-dropdown>
      </div>
    </TopBar>
    <div class="crm-main-section-body">
      <div v-if="api.fetch_activities_settings.send">
        <!-- Loading... -->
        <div class="latestShimmerDesign" style="height:40px;width:20%"></div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="latestShimmerDesign mt-20px" style="height:40px;width:15%"></div>
          <div class="latestShimmerDesign mt-20px" style="height:40px;width:20%"></div>
        </div>
        <div class="latestShimmerDesign mt-20px" style="height:30px;width:100%"></div>
        <div class="latestShimmerDesign mt-10px" v-for="n in 6" :key="n.id" style="height:30px;width:100%"></div>
      </div>
      <template v-else>
        <div class="d-flex align-items-center mb-3">
          <div class="mr-16px" v-if="filters.activities.activity_type_id" @click="filters.activities.activity_type_id='';fetchActivities();">
            <b>All</b>
          </div>
          <div 
            v-for="activity_type in activityTypes" 
            :key="activity_type.id" 
            @click="filters.activities.activity_type_id=activity_type.id;fetchActivities();" 
            class="newCloseButton onlyIcon mr-2"
            :class="{
              active: filters.activities.activity_type_id==activity_type.id,
            }"
          >
            <vb-icon :class="`${activity_type.name == 'Task' ? 'squared-done-icon' : ''}`" :icon="activity_type | activityTypeIcons" height="18px" width="18px"/>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div class="w-100 d-flex">
            <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
              <b-dropdown no-caret>
                <template #button-content>
                  <span class="d-flex align-items-center p-0">
                    <template v-if="selectedActivityFilter">
                      <span>{{ selectedActivityFilter.name }}</span>
                    </template>
                    <template v-else>
                      <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                      <span>Filter</span>
                    </template>
                    <b-icon icon="chevron-down" class="ml-2" />
                  </span>
                </template>
                <b-dropdown-item v-if="filters.activities.filter_id" @click="filters.activities.filter_id='';fetchActivities();">Clear Filter</b-dropdown-item>
                <b-dropdown-item v-for="filter in activityFilters" :key="filter.id" @click="filters.activities.filter_id=filter.id;fetchActivities();">
                  <vb-svg 
                    v-if="filters.activities.filter_id==filter.id" 
                    name="dialer-tick" 
                    width="10.5" 
                    height="7.584" 
                    viewBox="0 0 10.5 7.584" 
                    stroke-width="0" 
                    stroke="#005DFF" 
                    fill="none" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                  />
                  <span>{{ filter.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <button class="newButton onlyIcon ml-8px">
              <vb-icon icon="squared-pencil-icon" height="38px" width="38px" />
            </button>
          </div>
          <div class="d-flex align-items-center">
            <div class="totalsTextOnMain minWidth-maxContent mr-16px">{{response.activities | get_property('meta.total',0)}} Activity</div>
            <div class="crm-input-container mr-3 mb-0">
              <input placeholder="Search" v-model="filters.activities.query" @change="fetchActivities();" class="w-100"/>
              <vb-icon icon="crm-inputSearch-icon" />
            </div>
          </div>
        </div>
          <!-- :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" -->
        <!-- <b-table
          sticky-header="10000px"
          responsive
          selectable
          :fields="bColumns"
          :items="activities"
          :busy.sync="api.fetch_activities.send"
          @row-selected="selected.activities=$event"
        >
          <template #table-colgroup="scope">
            <b-col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width, minWidth: field.minWidth }"
            />
          </template>
          <template #cell(title)="data">
            <div>
              <b-form-checkbox :checked="data.rowSelected" />
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(type)="data">{{ data.item.type.name }}</template>
          <template #cell(due_date)="data">{{ data.item.displayAs.due_date }}</template>
          <template #cell(end_date)="data">{{ data.item.displayAs.end_date }}</template>
          <template #cell(owner_assigned_date)="data">{{ data.item.displayAs.owner_assigned_date }}</template>
          <template #cell(note)="data">{{ data.item.note }}</template>
          <template #cell(creator)="data">{{ data.item.creator.name }}</template>
          <template #cell(user)="data">{{ data.item.user.name }}</template>
          <template #cell(reminded_at)="data">{{ data.item.displayAs.reminded_at }}</template>
          <template #cell(completed_at)="data">{{ data.item.displayAs.completed_at }}</template>
          <template #cell(updated_at)="data">{{ data.item.displayAs.updated_at }}</template>
          <template #cell(created_at)="data">{{ data.item.displayAs.created_at }}</template>
          <template #cell(deals)="data">{{ data.item.deals }}</template>
          <template #cell(contacts)="data">{{ data.item.contacts }}</template>
          <template #cell(companies)="data">{{ data.item.companies }}</template>
          <template #cell(id)="data">{{ data.item.id }}</template>
        </b-table> -->
        <div class="overflow-X-Table latestGreyBox-9-9-2023 pt-0 mt-20px border-top-0 mt-20px">
          <vb-table :horizontalScrollVersion="true" 
            class="mt-0 latestTableDesign-withBlackBorders-again" 
            :loading="api.fetch_activities_settings.send || api.fetch_activities.send"
            :isListEmpty="activities.length==0" 
            :listLength="activities.length" 
            textNoRecord=" "
          >
            <template #header>
              <tr>
                <th>
                  <b-form-checkbox 
                    class="crm-checkbox" 
                    :disabled="api.action.send"
                    :checked="activities.every(i=>selected.activities.includes(i.id))"
                    @change="
                      activities.every(i=>selected.activities.includes(i.id)) ?
                        selected.activities=[] :
                        selected.activities=activities.map(i=>i.id) 
                    "
                  />
                </th>
                <th v-for="(column, index) in columns" :key="column.attribute" class="dialer-has-sort" style="width: max-content">
                  <template v-if="index==0 && selected.activities.length>0">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion">
                      <b-dropdown no-caret>
                        <template #button-content>
                          <span class="d-flex align-items-center p-0">
                            <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                            <span>Select Action ({{ selected.activities.length }} record)</span>
                            <b-icon icon="chevron-down" class="ml-2" />
                          </span>
                        </template>
                        <b-dropdown-item 
                          v-for="data in activityActions" 
                          :key="data.uriKey" 
                          @click="selected.action=data;data.withoutConfirmation?action():$modal.show(`${_uid}-action`)"
                        >
                          <span>{{ data.name }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                  <span v-else class="dialer-sorted-by ascending" style="width: max-content">{{column.label}}</span>
                </th>
              </tr>
            </template>
            <template #body>
              <tr v-for="activity in activities" :key="activity.id">
                <td>
                  <b-form-checkbox 
                    class="crm-checkbox" 
                    :disabled="api.remove.send.includes(activity.id) || api.action.send"
                    :checked="selected.activities.includes(activity.id)"
                    @change="
                      selected.activities.includes(activity.id)?
                        selected.activities.splice(selected.activities.indexOf(activity.id),1):
                        selected.activities.push(activity.id)
                    " 
                  />
                </td>
                <td v-for="(column, index) in columns" :key="column.attribute" style="width: max-content">
                  <div :class="`d-flex align-items-center justify-content-between`" :style="`width: ${index==0 ? '100%' : 'max-content'}`">
                    <template v-if="column.attribute=='title'">{{ activity.title }}</template>
                    <template v-else-if="column.attribute=='type'">{{ activity.type.name }}</template>
                    <template v-else-if="column.attribute=='due_date'">{{ activity.displayAs.due_date }}</template>
                    <template v-else-if="column.attribute=='end_date'">{{ activity.displayAs.end_date }}</template>
                    <template v-else-if="column.attribute=='owner_assigned_date'">{{ activity.displayAs.owner_assigned_date }}</template>
                    <template v-else-if="column.attribute=='note'">{{ activity.note }}</template>
                    <template v-else-if="column.attribute=='creator'">{{ activity.creator.name }}</template>
                    <template v-else-if="column.attribute=='reminded_at'">{{ activity.displayAs.reminded_at }}</template>
                    <template v-else-if="column.attribute=='completed_at'">{{ activity.displayAs.completed_at }}</template>
                    <template v-else-if="column.attribute=='updated_at'">{{ activity.displayAs.updated_at }}</template>
                    <template v-else-if="column.attribute=='created_at'">{{ activity.displayAs.created_at }}</template>
                    <template v-else-if="column.attribute=='deals'">{{ activity.deals }}</template>
                    <template v-else-if="column.attribute=='contacts'">{{ activity.contacts }}</template>
                    <template v-else-if="column.attribute=='companies'">{{ activity.companies }}</template>
                    <template v-else-if="column.attribute=='id'">{{ activity.id }}</template>
                    <div v-if="index==0" class="ml-auto whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD w-fit-content">
                      <b-dropdown no-caret >
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" />
                        </template>
                        <b-dropdown-item :disabled="api.remove.send.includes(activity.id)" @click="remove(activity)">
                          <span>Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 6" :key="n.id">
                <td><div class="latestShimmerDesign" style="height:24px;width:24px"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
    </div>
    <modal :name="`${_uid}-export`" class="crm-center-modal">
      <div class="crm-center-modal-header">
        <div class="crm-center-modal-header-title">Export Activities</div>
        <a class="newCloseButton" @click="$modal.hide(`${_uid}-export`)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </a>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
          <label>Select file type</label>
          <vb-select :disabled="api.export.send" v-model="forms.export.type" :options="[{text:'CSV',value:'csv'},{text:'XLS',value:'xls'},{text:'XLSX',value:'xlsx'}]" />
        </div>
        <b-form-radio-group v-model="forms.export.period" class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="today">
            <div class="latestGreyBox-heading-main">Today</div>
          </b-form-radio>
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="last_7_days">
            <div class="latestGreyBox-heading-main">Last 7 Days</div>
          </b-form-radio>
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="this_month">
            <div class="latestGreyBox-heading-main">This Month</div>
          </b-form-radio>
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="last_month">
            <div class="latestGreyBox-heading-main">Last Month</div>
          </b-form-radio>
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="">
            <div class="latestGreyBox-heading-main">All</div>
          </b-form-radio>
          <b-form-radio class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio" value="custom" style="border-bottom: 1px solid #DEDEDE;">
            <div class="latestGreyBox-heading-main">Custom</div>
          </b-form-radio>
        </b-form-radio-group>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px" v-if="filters.activities.filter_id">
          <template v-if="filters.activities.filter_id">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-4">
              <b-form-checkbox class="crm-checkbox w-100" :disabled="api.export.send" v-model="forms.export.apply_filter">
                <div class="latestGreyBox-heading-main">Export records based on the applied filters?</div>
              </b-form-checkbox>
            </div>
          </template>
        </div>
        <template v-if="forms.export.period=='custom'">
          <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned mt-20px">
            <label>Label</label>
            <vue-ctk-date-time-picker 
              :disabled="api.export.send" 
              v-bind="ctkDateTimePickerOptions" 
              class="date-time-input" 
              v-model="forms.export.date_range"
              format="YYYY-MM-DD"
              :range="true"
              :noHeader="true"
              :noShortcuts="true"
              :noButton="true"
            />
          </div>
        </template>
      </div>
      <div class="d-flex align-items-center mt-20px">
        <button class="fullWidthCloseButton w-50 mr-3" :disabled="api.export.send" @click="$modal.hide(`${_uid}-export`)">Cancel</button>
        <button class="fullWidthDoneButton w-50 ml-3" :disabled="api.export.send" @click="exportActivities()">Export</button>
      </div>
    </modal>
    <modal :name="`${_uid}-action`" class="crm-center-modal">
      <template v-if="selected.action">
        <div class="crm-center-modal-header">
          <div class="crm-center-modal-header-title">{{ selected.action.name }}</div>
          <a class="newCloseButton" @click="$modal.hide(`${_uid}-action`)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <!-- <h5>{{ selected.action.name }}</h5> -->
        <div v-for="field in selected.action.fields" :key="field.attribute">
          <div v-if="field.attribute=='activity_type_id'" class="latestGreyBox-9-9-2023">
            <div class="d-flex align-items-center">
              <div 
                v-for="activity_type in activityTypes" 
                :key="activity_type.id" 
                @click="api.action.send?'':selected.activity_type_id=activity_type.id;" 
                class="newCloseButton onlyIcon mr-2"
                :class="{
                  active: selected.activity_type_id==activity_type.id
                }"
              >
                <vb-icon :class="`${activity_type.name == 'Task' ? 'squared-done-icon' : ''}`" :icon="activity_type | activityTypeIcons" height="18px" width="18px"/>
              </div>
            </div>
          </div>
          <div v-if="field.attribute=='user_id'"  class="latestGreyBox-9-9-2023">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
              <vb-select
                v-model="selected.user_id"
                :disabled="api.action.send"
                :options="field.options"
                textField="name"
                valueField="id"
              >
              </vb-select>
            </div>
          </div>
        </div>
        <div v-if="selected.action.fields.length==0">{{ selected.action.message }}</div>
        <div class="d-flex align-items-center mt-20px">
          <button class="fullWidthCloseButton w-50 mr-10px" :disabled="api.action.send" @click="selected.action=null;selected.user_id='';selected.activity_type_id='';$modal.hide(`${_uid}-action`)">Cancel</button>
          <button class="fullWidthDoneButton w-50 ml-10px" :disabled="api.action.send" @click="action();">Confirm</button>
        </div>
      </template>
    </modal>
    <modal :name="`${_uid}-CreateActivity`"
      class="dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
    >
      <div class="CRM-modal-header">
        <div class="CRM-modal-header-text">Create activity</div>
        <a class="newCloseButton" @click="$modal.hide(`${_uid}-CreateActivity`)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </a>
      </div>
      <b-form class="latestGreyBox-9-9-2023">
        <div class="crm-input-container withoutIcon">
          <label>Title</label>
          <b-form-input placeholder="Title" />
        </div>
        <!-- <div class="d-flex align-items-center mb-16px">
          <button class="crm-button d-flex align-items-center mr-2">
            <vb-icon icon="crm-telephone-icon" height="18px" width="18px"/>
          </button>
          <button class="crm-button d-flex align-items-center mr-2">
            <b-icon icon="clock" font-scale="1.0" scale="1.2"/>
          </button>
          <button class="crm-button d-flex align-items-center mr-2">
            <vb-icon icon="crm-envelope-icon" height="23.11px" width="16px"/>
          </button>
          <button class="crm-button d-flex align-items-center mr-2">
            <vb-icon icon="crm-users-icon" height="20px" width="18px"/>
          </button>
          <button class="crm-button d-flex align-items-center mr-2">
            <b-icon icon="check-circle" font-scale="1.0" scale="1.2"/>
          </button>
        </div> -->
        <div class="d-flex align-items-center mb-16px">
          <div class="newCloseButton onlyIcon active mr-2">
            <vb-icon icon="squared-call-icon" height="18px" width="18px"/>
          </div>
          <div class="newCloseButton onlyIcon mr-2">
            <vb-icon icon="squared-clock-icon" font-scale="1.0" scale="1.2"/>
          </div>
          <div class="newCloseButton onlyIcon mr-2">
            <vb-icon icon="squared-mail-icon" height="23.11px" width="16px"/>
          </div>
          <div class="newCloseButton onlyIcon mr-2">
            <vb-icon icon="squared-multipleUsers-icon" height="20px" width="18px"/>
          </div>
          <div class="newCloseButton onlyIcon mr-2">
            <vb-icon icon="squared-done-icon" class="squared-done-icon" />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="crm-input-container VueCtkDateTimePicker subSectionLeftAligned">
            <vue-ctk-date-time-picker 
              v-bind="ctkDateTimePickerOptions" 
              :format="'YYYY-MM-DD HH:mm'" 
              id="start_time"
            />
          </div>
          <span class="mx-2 mb-16px"> - </span>
          <div class="crm-input-container VueCtkDateTimePicker subSectionRightAligned">
            <vue-ctk-date-time-picker 
              v-bind="ctkDateTimePickerOptions" 
              :format="'YYYY-MM-DD HH:mm'" 
              id="end_time"
            />
          </div>
        </div>
        <div class="w-100 d-flex align-items-end">
          <div class="crm-input-container w-25 min-width-250px mr-3">
            <label>Reminder</label>
            <div class="crm-input-inner w-100 position-relative">
              <b-icon icon="x" variant="danger" class="crm-input-icon"/>
              <b-form-input placeholder="minutes" />
            </div>
          </div>
          <div class="crm-main-dd dropdownVersion mr-3 mb-16px">
            <b-dropdown no-caret>
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span class="mr-2">minutes</span>
                  <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                </span>
              </template>
              <b-dropdown-item >
                <span> minutes </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> hours </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> days </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> weeks </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="d-flex align-items-center h-40px mb-16px">
            <div>before due</div>
          </div>
        </div>
        <div class="crm-input-container CallType">
          <label>Select user</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Select User">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container withoutIcon">
          <label>Description</label>
          <textarea class="w-100" placeholder="Description" />
        </div>
        <div class="crm-input-container withoutIcon">
          <label>Notes</label>
          <textarea class="w-100" placeholder="Notes" />
        </div>
        <div class="crm-input-container CallType">
          <label>Guests</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Guests">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container CallType">
          <label>Deals</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Deals">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container CallType">
          <label>Contacts</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Contacts">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container CallType">
          <label>Companies</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Companies">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>

        <b-button type="submit" class="fullWidthDoneButton mt-20px">
          Submit
        </b-button>
      </b-form>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { GET_CRM_USER } from '@/store/helper/getters'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ctkDateTimePicker } from '@/mixin'
import { VOIP_API } from '@/utils';
import TopBar from './widgets/TopBar.vue';
import moment from 'moment-timezone';
export default {
  name: 'Activities',
  mixins: [ctkDateTimePicker],
  components: {
    vSelect,
    TopBar,
  },
  inject:[
    'isEmpty',
    'appNotify'
  ],
  data(){
    return {
      forms: {
        export: this.$helperFunction.formInstance({
          data: {
            type: 'csv',
            period: '',
            date_range: {
              start: '',
              end: '',
            },
            apply_filter: true,
          },
        })
      },
      api: {
        fetch_activities: this.$helperFunction.apiInstance(),
        fetch_activities_settings: this.$helperFunction.apiInstance(),
        action: this.$helperFunction.apiInstance(),
        remove: this.$helperFunction.apiInstance({ send: [] }),
        export: this.$helperFunction.apiInstance(),
      },
      response: {
        activities: {},
        settings: {},
      },
      filters: {
        activities: {
          activity_type_id: '',
          filter_id: '',
          query: '',
        },
      },
      paginations: {
        activities: {
          page: 1,
        },
      },
      selected: {
        activities: [],
        activity_type_id: '',
        user_id: '',
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USER,
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
    columns(){ return this.response.settings?.columns ?? [] },
    // bColumns(){ return this.columns?.map(i=>({ stickyColumn: i.primary, label: i.label, key: i.attribute })) },
    activityFilters(){ return this.response.settings?.filters ?? [] },
    activityActions(){ return this.response.settings?.actions ?? [] },
    selectedActivityFilter(){ return this.response.settings?.filters?.find?.(i=>i.id==this.filters.activities.filter_id) },
    activityTypes(){ return this.response.settings?.rules?.find(i=>i.id=='activity_type_id')?.options ?? [] },
    activities(){ return this.response.activities?.data ?? [] },
  },
  filters: {
    activityTypeIcons(value){
      if(value.name=='Call') return 'squared-call-icon'
      else if(value.name=='Deadline') return 'squared-clock-icon'
      else if(value.name=='Email') return 'squared-mail-icon'
      else if(value.name=='Meeting') return 'squared-multipleUsers-icon'
      else if(value.name=='Task') return 'squared-done-icon'
    },
  },
  methods: {
    async fetchSettings(){
      if(this.api.fetch_activities_settings.send) return;
      try {
        this.api.fetch_activities_settings.send=true
        const { data: settings } = await VOIP_API.endpoints.crm.activities.tableSettings({
          
        })
        this.response.settings=settings
        this.fetchActivities()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_activities_settings.send=false
      }
    },
    async fetchActivities(){
      if(this.api.fetch_activities.send) return;
      try {
        this.api.fetch_activities.send=true
        const { data: activities } = await VOIP_API.endpoints.crm.activities.table({
          per_page: this.response.settings.perPage,
          page: this.paginations.activities.page,
          order: this.response.settings.order,
          ...this.filters.activities.activity_type_id ? { activity_type_id: this.filters.activities.activity_type_id } : {},
          ...this.filters.activities.query ? { q: this.filters.activities.query } : {},
          ...this.selectedActivityFilter ? { rules: this.selectedActivityFilter.rules } : {},
        })
        this.response.activities=activities
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_activities.send=false
      }
    },
    async action(){
      const action = this.selected.action
      if(this.api.action.send || !action) return;
      const user_id_field = action.fields.find(i=>i.attribute=='user_id')
      const activity_type_id_field = action.fields.find(i=>i.attribute=='activity_type_id')
      if(activity_type_id_field && !this.selected.activity_type_id) {
        return;
      }
      if(user_id_field && !this.selected.user_id) {
        return;
      }
      try {
        this.api.action.send=true
        await VOIP_API.endpoints.crm.activities.action(action.uriKey,{
          ids: this.selected.activities,
          ...this.selected.activity_type_id ? { activity_type_id: this.selected.activity_type_id } : {},
          ...this.selected.user_id ? { user_id: this.selected.user_id } : {},
        })
        this.fetchActivities()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.action.send=false
        this.$modal.hide(`${this._uid}-action`)
        this.selected.activities=[]
        this.selected.user_id=''
        this.selected.activity_type_id=''
      }
    },
    async remove(activity){
      if(!activity || this.api.remove.send.includes(activity.id)) return;
      try {
        this.api.remove.send.push(activity.id)
        const { confirm } = await this.$appConfirmation({
          title: "Delete",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.crm.activities.remove(activity.id)
        this.fetchActivities()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.remove.send.splice(this.api.remove.send.indexOf(activity.id),1)
      }
    },
    async exportActivities(){
      if(this.api.export.send) return;
      try {
        this.api.export.send=true
        const { data } = await VOIP_API.endpoints.crm.activities.export({
          filters: this.forms.export.apply_filter && this.selectedActivityFilter ? this.selectedActivityFilter.rules : null,
          period: this.forms.export.period=='custom' ? { 
            start: this.forms.export.date_range.start, 
            end: this.forms.export.date_range.end 
          } : !this.forms.export.period ? null : this.forms.export.period,
          type: this.forms.export.type
        })
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `activities`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.export.send=false
      }
    },
  },
  mounted(){
    this.forms.export.date_range.start=moment().subtract(5,'days').format('YYYY-MM-DD')
    this.forms.export.date_range.end=moment().format('YYYY-MM-DD')
    this.fetchSettings()
  },
}
</script>

<style>

</style>