<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        General
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
      <!-- <button class="crm-button">create Activities</button> -->
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="crm-card-heading my-0">Dark Logo</div>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2">
        <vb-icon icon="crm-voipbusiness-darkLogo-icon" height="57px" width="47px" />
        <button class="newButton">
          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Update Image
          </span>
        </button>
      </div>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="crm-card-heading my-0">Light Logo</div>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2">
        <vb-icon icon="crm-voipbusiness-lightLogo-icon" height="57px" width="47px" />
        <button class="newButton">
          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Update Image
          </span>
        </button>
      </div>
    </div>
    <div class="crm-card p-32">
      <div class="crm-card-heading my-0">Currency</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>PKR</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> PKR </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> GBP </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> USD </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="crm-card-heading mb-0 mt-4">System Email Account</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>system@system.com</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> system@system.com </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> system@system.com </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> system@system.com </span>
            </b-dropdown-item>
        </b-dropdown>
      </div>
      <p class="crm-card-text wd-70" >
        Select Inbox connected email account that will be used to send system related mails like user assigned to contact, activity due reminder, user invites, etc...
      </p>
      <div class="crm-card-heading mt-0">Allowed upload extensions</div>
      <div class="crm-input-container mb-0">
        <textarea :placeholder="allowedUploadfileExtentions" class="w-100" rows="5" />
      </div>
      <p class="crm-card-text">
        Enter coma separated extensions to be whitelisted for file storage.
      </p>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex flex-column wd-70">
          <div class="crm-card-heading my-0">Require calling prefix on phone numbers</div>
          <p class="crm-card-text w-100 mb-0" >
            Most call integrations are requiring phone numbers to be in E.164 format. 
            Enabling this option will make sure that no phone numbers are 
            entered without a country-specific calling prefix.
          </p>
        </div>
        <b-form-checkbox
          ref="something"
          class="crm-switch"
          name="check-button"
          switch
        />
      </div>
    </div>
    <div class="crm-card p-32">
      <div class="crm-toaster-relative">
        <vb-icon icon="crm-info-icon" height="19px" width="19px"/>
        <p class="crm-card-text w-100 my-0" >
          Updating these settings won't affect your user account settings as these settings are general, 
          update the same settings in your user account instead if you are looking to 
          update these options.
        </p>
      </div>
      <div class="crm-card-heading mb-0 mt-4">Date Format</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>d-m-Y</span>
              <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> d-m-Y </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="crm-card-heading mb-0 mt-4">Time Format</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>H: [19:13]</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> H: [19:13] </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="crm-card-heading mb-0 mt-4">Week Starts On</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>Sunday</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> Sunday </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <button class="newDoneButton smaller mt-20px" type="button">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Save</span>
      </button>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset mb-0" style="color:black;">
        Company Information
      </h2>
      <div class="crm-card-heading mb-0 mt-4">Company Name</div>
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>Company Name</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> Company Name </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="crm-card-heading mb-0 mt-4">Country</div>
      <!-- put dropdown which has a search filter instead of the below dropdown -->
      <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62">
        <b-dropdown class="" no-caret>
          <template #button-content>
            <span class="d-flex justify-content-between w-100 align-items-center p-0">
              <span>Country</span>
              <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> Country </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <button class="newDoneButton smaller mt-20px" type="button">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Save</span>
      </button>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset mb-0" style="color:black;">
        Privacy Policy
      </h2>
      <div class="crm-input-container mt-5 mb-0">
        <!-- put text editor like ck editor instead of textarea given below -->
        <textarea :placeholder="allowedUploadfileExtentions" class="w-100" rows="5" />
      </div>
      <p class="crm-card-text">
        If you don't have privacy policy, you can configure one here, view the privacy policy at the following URL: https://demo.concordcrm.com/privacy-policy
      </p>
      <button class="newDoneButton smaller" type="button">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Save</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'General',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>