var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `${_vm.design == 'minimum' ? 'minimulAudioPlayer-container' : _vm.design == 'blueAudio' || _vm.design == 'OnlyPreviewAudio' ? 'blueAudio-container' : _vm.design == 'forMonitorDashboard' ? 'forMonitorDashboard-audio' : 'w-inherit'}`
  }, [_c('audio', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "timeupdate": function ($event) {
        return _vm.onTimeChange($event);
      },
      "loadedmetadata": function ($event) {
        return _vm.onAudioLoad($event);
      },
      "play": function ($event) {
        return _vm.onPlaying($event);
      }
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.src,
      "type": _vm.type
    },
    on: {
      "error": _vm.onError
    }
  })]), _vm.design == 'Transcript' ? [_c('div', {
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "TranscriptAudio-container"
  }, [_c('div', {
    staticClass: "TranscriptAudio-speed"
  }, [_vm._v("1.0x")]), _c('div', {
    staticClass: "TranscriptAudio-controls mx-19px"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "minus-10-icon",
      "width": "25.266px",
      "height": "25.227px"
    },
    on: {
      "click": function ($event) {
        return _vm.backward(10);
      }
    }
  }), _c('div', {
    staticClass: "mx-19px",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": _vm.is_play ? 'audioTranscript-pause-icon' : 'audioTranscript-play-icon',
      "width": "48px",
      "height": "48px"
    }
  })], 1), _c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "plus-10-icon",
      "width": "25.266px",
      "height": "25.227px"
    },
    on: {
      "click": function ($event) {
        return _vm.forward(10);
      }
    }
  })], 1), _vm._t("player-end")], 2), _c('div', {
    staticClass: "TranscriptAudio-progress-container"
  }, [_c('p', {
    staticClass: "TranscriptAudio-time"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.duration, true)))]), _c('div', {
    staticClass: "TranscriptAudio-progress"
  }, [_c('b-progress', {
    attrs: {
      "aria-disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "value": _vm.audioCom.progress,
      "max": 100
    }
  }), _c('input', {
    attrs: {
      "disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "type": "range",
      "min": "0",
      "max": "100",
      "step": "1"
    },
    domProps: {
      "value": _vm.audioCom.progress
    },
    on: {
      "input": function ($event) {
        $event.stopPropagation();
        return _vm.onInputRange($event);
      }
    }
  })], 1), _c('p', {
    staticClass: "TranscriptAudio-time"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.heard, true)))])])])] : _vm.design == 'NoTranscript' ? [_c('div', {
    staticClass: "newAudioPlyer"
  }, [_c('div', {
    staticClass: "newAudioPlyer-controls"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "minus-10-icon",
      "width": "21px",
      "height": "21px"
    }
  }), _c('div', {
    staticClass: "mx-15px",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": _vm.is_play ? 'audioTranscript-pause-icon' : 'audioTranscript-play-icon',
      "width": "40px",
      "height": "40px"
    }
  })], 1), _c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "plus-10-icon",
      "width": "21px",
      "height": "21px"
    }
  })], 1), _c('div', {
    staticClass: "newAudioPlyer-progress-container"
  }, [_c('div', {
    staticClass: "newAudioPlyer-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.duration, true)))])]), _c('div', {
    staticClass: "newAudioPlyer-progress"
  }, [_c('b-progress', {
    attrs: {
      "aria-disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "value": _vm.audioCom.progress,
      "max": 100
    }
  }), _c('input', {
    attrs: {
      "disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "type": "range",
      "min": "0",
      "max": "100",
      "step": "1"
    },
    domProps: {
      "value": _vm.audioCom.progress
    },
    on: {
      "input": function ($event) {
        $event.stopPropagation();
        return _vm.onInputRange($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "newAudioPlyer-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.heard, true)))])])]), _vm._t("player-end")], 2)] : _vm.design == 'minimum' ? [_c('div', {
    staticClass: "minimulAudioPlayer-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "minimulAudioPlayer-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "minimulAudioPlayer-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("time_audio")(_vm.is_play ? _vm.audioCom.heard : _vm.audioCom.duration, true)) + " ")])])])] : _vm.design == 'forMonitorDashboard' ? [_c('div', {
    staticClass: "forMonitorDashboard-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "forMonitorDashboard-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "forMonitorDashboard-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("time_audio")(_vm.is_play ? _vm.audioCom.heard : _vm.audioCom.duration, true)) + " ")])])])] : _vm.design == 'onlyPlayPause' ? [_c('div', {
    staticClass: "onlyPlayPause-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "onlyPlayPause-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)])] : _vm.design == 'insideChatAudioRecord' ? [_c('div', {
    staticClass: "insideChatAudioRecord-container"
  }, [_c('div', {
    staticClass: "insideChatAudioRecord-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "insideChatAudioRecord-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "insideChatAudioRecord-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("time_audio")(_vm.is_play ? _vm.audioCom.heard : _vm.audioCom.duration, true)) + " ")])])])])] : _vm.design == 'blueAudio' ? [_c('div', {
    staticClass: "blueAudio-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "blueAudio-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "blueAudio-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.is_play ? _vm.audioCom.heard : _vm.audioCom.duration, true)))])])])] : _vm.design == 'OnlyPreviewAudio' ? [_c('div', {
    staticClass: "blueAudio-inner",
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "blueAudio-button-container",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _vm._m(0)])] : _vm.design == 'whiteBgBlackBorder' ? [_c('div', {
    class: `whiteBgBlackBorder-container ${_vm.is_error ? 'position-relative widthBRupdated' : ''}`,
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "whiteBgBlackBorder-inner"
  }, [_c('div', {
    staticClass: "whiteBgBlackBorder-button",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "whiteBgBlackBorder-rightSide"
  }, [_c('p', {
    staticClass: "whiteBgBlackBorder-time left"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.duration, true)))]), _c('div', {
    staticClass: "whiteBgBlackBorder-progress-container"
  }, [_c('div', {
    staticClass: "whiteBgBlackBorder-progress-justBackground"
  }), _c('div', {
    staticClass: "whiteBgBlackBorder-progress-buffer",
    style: `width:${_vm.audioCom.buffered}%;`
  }), _c('div', {
    staticClass: "whiteBgBlackBorder-progress-heared",
    style: `width:${_vm.audioCom.progress}%;`
  }), _c('input', {
    attrs: {
      "disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "type": "range",
      "min": "0",
      "max": "100",
      "step": "1"
    },
    domProps: {
      "value": _vm.audioCom.progress
    },
    on: {
      "input": function ($event) {
        $event.stopPropagation();
        return _vm.onInputRange($event);
      }
    }
  })]), _c('p', {
    staticClass: "whiteBgBlackBorder-time right"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.heard, true)))])])])])] : [_vm.getIsMobile ? _c('div', {
    staticClass: "mobile-audio-playback"
  }, [_c('div', {
    staticClass: "mobile-audio-playback-inner"
  }, [_c('div', {
    class: ['svg-container', !_vm.is_play ? 'play' : 'pause'],
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": !_vm.is_play ? 'mobile-audioPlayer-play-icon' : 'mobile-audioPlayer-pause-icon',
      "width": "8.079",
      "height": "9.433"
    }
  })], 1), _c('div', {
    staticClass: "time-container"
  }, [_c('div', {
    staticClass: "time right"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.duration, true)))]), _c('div', {
    staticClass: "time"
  }, [_vm._v("/")]), _c('div', {
    staticClass: "time left"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.heard, true)))])])])]) : [_c('div', {
    class: `AudioPlayer-container ${_vm.is_play ? 'isPlaying' : ''} ${_vm.is_error ? 'position-relative widthBRupdated' : ''}`,
    attrs: {
      "id": `audio-player-${_vm.id}`
    }
  }, [_c('div', {
    staticClass: "AudioPlayer-inner"
  }, [_c('div', {
    staticClass: "AudioPlayer-button",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.disabled ? '' : _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead',
      "width": "14",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('div', {
    staticClass: "AudioPlayer-rightSide"
  }, [_c('p', {
    staticClass: "AudioPlayer-time left"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.duration, true)))]), _c('div', {
    staticClass: "AudioPlayer-progress-container"
  }, [_c('div', {
    staticClass: "AudioPlayer-progress-justBackground"
  }), _c('div', {
    staticClass: "AudioPlayer-progress-buffer",
    style: `width:${_vm.audioCom.buffered}%;`
  }), _c('div', {
    staticClass: "AudioPlayer-progress-heared",
    style: `width:${_vm.audioCom.progress}%;`
  }), _c('input', {
    attrs: {
      "disabled": _vm.disabled,
      "id": `${_vm.id}-progress`,
      "type": "range",
      "min": "0",
      "max": "100",
      "step": "1"
    },
    domProps: {
      "value": _vm.audioCom.progress
    },
    on: {
      "input": function ($event) {
        $event.stopPropagation();
        return _vm.onInputRange($event);
      }
    }
  })]), _c('p', {
    staticClass: "AudioPlayer-time right"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.audioCom.heard, true)))])])])])]]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "blueAudio-time"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Play preview")])]);

}]

export { render, staticRenderFns }