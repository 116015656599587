<template>
  <div :class="`${design=='minimum' ? 'minimulAudioPlayer-container' : design=='blueAudio' || design=='OnlyPreviewAudio' ? 'blueAudio-container' : design=='forMonitorDashboard' ? 'forMonitorDashboard-audio' : 'w-inherit'}`">
    <!-- v-show="!is_error" -->
    <audio :id="id" @timeupdate="onTimeChange($event)" @loadedmetadata="onAudioLoad($event)" @play="onPlaying($event)">
      <source :src="src" @error="onError" :type="type" />
    </audio>
    <template v-if="design=='Transcript'">
      <div :id="`audio-player-${id}`">
        <div class="TranscriptAudio-container">
          <div class="TranscriptAudio-speed">1.0x</div>
          <div class="TranscriptAudio-controls mx-19px">
            <vb-icon @click="backward(10)" class="cursor_pointer_no_hover" icon="minus-10-icon" width="25.266px" height="25.227px" />
            <div class="mx-19px" @click.stop="disabled ? '' :is_play = !is_play">
              <vb-icon class="cursor_pointer_no_hover" :icon="is_play ? 'audioTranscript-pause-icon' : 'audioTranscript-play-icon'" width="48px" height="48px" />
            </div>
            <vb-icon @click="forward(10)" class="cursor_pointer_no_hover" icon="plus-10-icon" width="25.266px" height="25.227px" />
          </div>
          <slot name="player-end" />
        </div>
        <div class="TranscriptAudio-progress-container">
          <p class="TranscriptAudio-time">{{ audioCom.duration | time_audio(true) }}</p>
          <div class="TranscriptAudio-progress">
            <b-progress :aria-disabled="disabled" :id="`${id}-progress`" :value="audioCom.progress" :max="100" />
            <input :disabled="disabled" :id="`${id}-progress`" type="range" :value="audioCom.progress" min="0" max="100" step="1" @input.stop="onInputRange($event)" />
          </div>
          <p class="TranscriptAudio-time">{{ audioCom.heard | time_audio(true) }}</p>
        </div>
      </div>
    </template>
    <template v-else-if="design=='NoTranscript'">
      <div class="newAudioPlyer">
        <div class="newAudioPlyer-controls">
          <vb-icon class="cursor_pointer_no_hover" icon="minus-10-icon" width="21px" height="21px" />
          <div class="mx-15px" @click.stop="disabled ? '' :is_play = !is_play">
            <vb-icon class="cursor_pointer_no_hover" :icon="is_play ? 'audioTranscript-pause-icon' : 'audioTranscript-play-icon'" width="40px" height="40px" />
          </div>
          <vb-icon class="cursor_pointer_no_hover" icon="plus-10-icon" width="21px" height="21px" />
        </div>
        <div class="newAudioPlyer-progress-container">
          <div class="newAudioPlyer-time">
            <p class="mb-0">{{ audioCom.duration | time_audio(true) }}</p>
          </div>
          <div class="newAudioPlyer-progress">
            <b-progress :aria-disabled="disabled" :id="`${id}-progress`" :value="audioCom.progress" :max="100" />
            <input :disabled="disabled" :id="`${id}-progress`" type="range" :value="audioCom.progress" min="0" max="100" step="1" @input.stop="onInputRange($event)" />
          </div>
          <div class="newAudioPlyer-time">
            <p class="mb-0">{{ audioCom.heard | time_audio(true) }}</p>
          </div>
        </div>
        <slot name="player-end" />
      </div>
    </template>
    <template v-else-if="design=='minimum'">
      <div :id="`audio-player-${id}`" class="minimulAudioPlayer-inner">
        <div @click.stop="disabled ? '' :is_play = !is_play" class="minimulAudioPlayer-button-container">
          <vb-svg 
            :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
            width="14" 
            stroke="currentColor" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            stroke-width="0" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
          />
        </div>
        <div class="minimulAudioPlayer-time">
          <p class="mb-0">
            <!-- {{ audioCom.duration | time_audio(true) }} -->
            {{ is_play ? audioCom.heard : audioCom.duration | time_audio(true) }}
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="design=='forMonitorDashboard'">
      <div :id="`audio-player-${id}`" class="forMonitorDashboard-inner">
        <div @click.stop="disabled ? '' :is_play = !is_play" class="forMonitorDashboard-button-container">
          <vb-svg 
            :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
            width="14" 
            stroke="currentColor" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            stroke-width="0" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
          />
        </div>
        <div class="forMonitorDashboard-time">
          <p class="mb-0">
            <!-- {{ audioCom.duration | time_audio(true) }} -->
            {{ is_play ? audioCom.heard : audioCom.duration | time_audio(true) }}
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="design=='onlyPlayPause'">
      <div :id="`audio-player-${id}`" class="onlyPlayPause-inner">
        <div @click.stop="disabled ? '' :is_play = !is_play" class="onlyPlayPause-button-container">
          <vb-svg 
            :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
            width="14" 
            stroke="currentColor" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            stroke-width="0" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
          />
        </div>
      </div>
    </template>
    <template v-else-if="design=='insideChatAudioRecord'">
      <div class="insideChatAudioRecord-container">
        <div :id="`audio-player-${id}`" class="insideChatAudioRecord-inner">
          <div @click.stop="disabled ? '' :is_play = !is_play" class="insideChatAudioRecord-button-container">
            <vb-svg 
              :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
              width="14" 
              stroke="currentColor" 
              height="16" 
              viewBox="0 0 14 16" 
              fill="none" 
              stroke-width="0" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
          </div>
          <div class="insideChatAudioRecord-time">
            <p class="mb-0">
              <!-- {{ audioCom.duration | time_audio(true) }} -->
              {{ is_play ? audioCom.heard : audioCom.duration | time_audio(true) }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="design=='blueAudio'">
      <div :id="`audio-player-${id}`" class="blueAudio-inner">
        <div @click.stop="disabled ? '' :is_play = !is_play" class="blueAudio-button-container">
          <vb-svg 
            :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
            width="14" 
            stroke="currentColor" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            stroke-width="0" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
          />
        </div>
        <div class="blueAudio-time">
          <p class="mb-0">{{ is_play ? audioCom.heard : audioCom.duration | time_audio(true) }}</p>
        </div>
      </div>
    </template>
    <template v-else-if="design=='OnlyPreviewAudio'">
      <div :id="`audio-player-${id}`" class="blueAudio-inner">
        <div @click.stop="disabled ? '' :is_play = !is_play" class="blueAudio-button-container">
          <vb-svg 
            :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
            width="14" 
            stroke="currentColor" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            stroke-width="0" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
          />
        </div>
        <div class="blueAudio-time">
          <p class="mb-0">Play preview</p>
        </div>
      </div>
    </template>
    <template v-else-if="design=='whiteBgBlackBorder'">
      <div :id="`audio-player-${id}`" :class="`whiteBgBlackBorder-container ${is_error ? 'position-relative widthBRupdated' : ''}`">
        <div class="whiteBgBlackBorder-inner">
          <div class="whiteBgBlackBorder-button" @click.stop="disabled ? '' :is_play = !is_play">
            <vb-svg 
              :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
              width="14" 
              stroke="currentColor" 
              height="16" 
              viewBox="0 0 14 16" 
              fill="none" 
              stroke-width="0" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
          </div>
          <div class="whiteBgBlackBorder-rightSide">
            <p class="whiteBgBlackBorder-time left">{{ audioCom.duration | time_audio(true) }}</p>
            <div class="whiteBgBlackBorder-progress-container">
              <div class="whiteBgBlackBorder-progress-justBackground"/>
              <div class="whiteBgBlackBorder-progress-buffer" :style="`width:${audioCom.buffered}%;`" />
              <div class="whiteBgBlackBorder-progress-heared" :style="`width:${audioCom.progress}%;`" />
              <input :disabled="disabled" :id="`${id}-progress`" type="range" :value="audioCom.progress" min="0" max="100" step="1" @input.stop="onInputRange($event)" />
            </div>
            <p class="whiteBgBlackBorder-time right">{{ audioCom.heard | time_audio(true) }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="getIsMobile" class="mobile-audio-playback">
        <div class="mobile-audio-playback-inner">
          <div :class="['svg-container',!is_play ?'play' : 'pause']" @click.stop="disabled ? '' :is_play = !is_play">
            <vb-icon :icon="!is_play ?'mobile-audioPlayer-play-icon' : 'mobile-audioPlayer-pause-icon'" width="8.079" height="9.433"/>
          </div>
          <div class="time-container">
            <div class="time right">{{ audioCom.duration | time_audio(true) }}</div>
            <div class="time">/</div>
            <div class="time left">{{ audioCom.heard | time_audio(true) }}</div>
          </div>
        </div>
      </div>
      <template v-else>
        <div :id="`audio-player-${id}`" :class="`AudioPlayer-container ${is_play ? 'isPlaying' : ''} ${is_error ? 'position-relative widthBRupdated' : ''}`">
          <div class="AudioPlayer-inner">
            <div class="AudioPlayer-button" @click.stop="disabled ? '' :is_play = !is_play">
              <vb-svg 
                :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
                width="14" 
                stroke="currentColor" 
                height="16" 
                viewBox="0 0 14 16" 
                fill="none" 
                stroke-width="0" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
              />
            </div>
            <div class="AudioPlayer-rightSide">
              <p class="AudioPlayer-time left">{{ audioCom.duration | time_audio(true) }}</p>
              <div class="AudioPlayer-progress-container">
                <div class="AudioPlayer-progress-justBackground"/>
                <div class="AudioPlayer-progress-buffer" :style="`width:${audioCom.buffered}%;`" />
                <div class="AudioPlayer-progress-heared" :style="`width:${audioCom.progress}%;`" />
                <input :disabled="disabled" :id="`${id}-progress`" type="range" :value="audioCom.progress" min="0" max="100" step="1" @input.stop="onInputRange($event)" />
              </div>
              <p class="AudioPlayer-time right">{{ audioCom.heard | time_audio(true) }}</p>
            </div>
          </div>
        </div>
        <!--<div :id="`audio-player-${id}`" :class="`dialer-audio-playback ${is_error ? 'position-relative widthBRupdated' : ''}`">
          <div class="dialer-audio-playback-inner">
            <div class="dialer-audio-message">
              <div @click.stop="disabled ? '' :is_play = !is_play">
                <vb-svg 
                  :name="!is_play ? 'dialer-play-arrowhead' : 'dialer-pause-arrowhead'" 
                  width="14" 
                  stroke="currentColor" 
                  height="16" 
                  viewBox="0 0 14 16" 
                  fill="none" 
                  stroke-width="0" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </div>
              <div class="dialer-audio-progress">
                <small>{{ audioCom.duration | time_audio(true) }}</small>
                <!~~ <div class="d-flex w-100 position-relative">
                  <div class="w-100 position-relative" style="height:5px">
                    <div class="buffer position-absolute" :style="`z-index:1;height:100%;width:${audioCom.buffered}%;background-color:red;`" />
                    <div class="progress position-absolute" :style="`z-index:2;height:100%;width:${audioCom.progress}%;background-color:green;`" />
                  </div>
                  <input 
                    class="w-100"
                    style="left:0px;z-index:3;"
                    :disabled="disabled" 
                    :id="`${id}-progress`" 
                    type="range" 
                    :value="audioCom.progress" 
                    min="0" 
                    max="100" 
                    step="1" 
                    @input.stop="onInputRange($event)" 
                  />
                </div> ~~>
                <b-progress :aria-disabled="disabled" :id="`${id}-progress`" :value="audioCom.progress" :max="100" />
                <input :disabled="disabled" :id="`${id}-progress`" type="range" :value="audioCom.progress" min="0" max="100" step="1" @input.stop="onInputRange($event)" />
                <small>{{ audioCom.heard | time_audio(true) }}</small>
                <!~~ <span v-if="is_error">URL ERROR</span> ~~>
              </div>
            </div>
          </div>
          <div class="URLerrorText" v-if="is_error">URL ERROR</div>
        </div>-->
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { events, $fn } from "../../utils";
export default {
  name: "vb-audio-player",
  props: {
    id: {
      type: String,
      default: () => { return $fn.makeid(30); },
    },
    type: {
      type: String,
      default: "audio/mp3",
    },
    src: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    design: {
      type: String,
      default: '',
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_play: false,
      is_error: false,
      audio: {
        duration: 0,
        currentTime: 0,
        buffered: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
    audioCom(){
      const duration = Math.floor(this.audio.duration==Infinity ? 0 : this.audio.duration || 0)
      const heard_duration = Math.floor(this.audio.currentTime);
      const heard = heard_duration == Infinity ? 0 : heard_duration
      return {
        duration: duration,
        heard: heard,
        progress: Math.floor((heard / duration) * 100) || 0,
        buffered: Math.floor((this.audio.buffered / duration) * 100) || 0
      }
    },
  },
  filters: {
    durationShow(value) {
      if (value < 60) return `00:${value > 10 ? value : `0${value}`}`;
      return value;
    },
  },
  watch: {
    is_play(is_play) {
      const audio = document.getElementById(this.id)
      if (is_play) {
        audio?.play();
      } else {
        audio?.pause();
      }
    },
    "src"(){
      document.getElementById(this.id)?.load?.();
    },
    "audio.currentTime"(currentTime){
      this.$emit('currentTime',currentTime)
    },
  },
  methods: {
    onAudioLoad(Event) {
      let vm = this
      const mp3file = this.src;
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const request = new XMLHttpRequest();
      request.open('GET', mp3file, true);
      request.responseType = 'arraybuffer';
      request.onload = function() {
        audioContext.decodeAudioData(request.response, function(buffer) {
          vm.audio.buffered = buffer.duration;
        });
      };
      request.send();
      this.is_error = false;
      this.is_play = false;
      this.audio.currentTime = Event.target.currentTime;
      this.audio.duration = Event.target.duration;
      if(Event.target.duration==Infinity) {
        const getDuration = function (url, next) {
          let _player = new Audio(url);
          _player.addEventListener("durationchange", function (event) {
            let duration = event.target.duration
            if(duration!=Infinity) {
              _player.remove();
              next(duration);
            }
          }, false);
          _player.load();
          _player.currentTime = 24*60*60;
          _player.volume = 0;
          _player.play();
        };
        getDuration(mp3file, function(duration) {
          vm.audio.duration = duration;
          vm.$nextTick().then(()=>{
            if(vm.autoPlay) {
              vm.is_play=true
            }
          })
        });
      } else {
        this.$nextTick().then(()=>{
          if(vm.autoPlay) {
            vm.is_play=true
          }
        })
      }
    },
    forward(seconds){
      const audio = document.getElementById(this.id);
      const new_time = audio.currentTime + seconds
      audio.currentTime = new_time>audio.duration ? audio.duration : new_time;
    },
    backward(seconds){
      const audio = document.getElementById(this.id);
      const new_time = audio.currentTime - seconds
      audio.currentTime = new_time<0 ? 0 : new_time;
    },
    onTimeChange(Event) {
      const currentTime = Event.target.currentTime
      if (this.audio.duration==currentTime) {
        this.is_play = false
        Event.target.currentTime=0
      }
      this.audio.currentTime = currentTime;
    },
    onError(Event) {
      this.is_error = true;
      this.is_play = false;
      this.audio.currentTime=0
      this.audio.duration=0
      this.audio.buffered=0
      this.$emit("error", Event);
    },
    onInputRange(Event) {
      const audio = document.getElementById(this.id);
      audio.currentTime = (Event.target.value / 100) * audio.duration;
    },
    onPlaying() {
      this.$root.$emit(events.playing_audio, {
        id: this.id,
      });
    },
    onPlayingAudio(message) {
      if (message?.id !== this.id && this.is_play) {
        this.is_play = false;
      }
    },
  },
  created() {
    this.$root.$on(events.playing_audio, this.onPlayingAudio);
  },
  deactivated(){
    this.is_play=false
  },
  beforeDestroy() {
    this.$root.$off(events.playing_audio, this.onPlayingAudio);
  },
};
</script>

<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          line-height: 13px;
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}
</style>