// SECTION - Pamarly
export const SET_PAMARLY_USER = 'SET_PAMARLY_USER'
export const SET_PAMARLY_TOKEN = 'SET_PAMARLY_TOKEN'
export const SET_PAMARLY_REFRESH_TOKEN = 'SET_PAMARLY_REFRESH_TOKEN'

// SECTION - CRM
export const SET_CRM_TOKEN = 'SET_CRM_TOKEN'
export const SET_CRM_PIPELINES = 'SET_CRM_PIPELINES'
export const SET_CRM_USER = 'SET_CRM_USER'
export const SET_CRM_API_URL = 'SET_CRM_API_URL'

// SECTION - CRM
export const SET_STORE_API_ERRORS = 'SET_STORE_API_ERRORS'
export const SET_API_ERRORS = 'SET_API_ERRORS'
export const ADD_API_ERROR = 'ADD_API_ERROR'
// export const SET_STORE_CENTRIFUGO_ERRORS = 'SET_STORE_CENTRIFUGO_ERRORS'
// export const SET_CENTRIFUGO_ERRORS = 'SET_CENTRIFUGO_ERRORS'
// export const ADD_CENTRIFUGO_ERROR = 'ADD_CENTRIFUGO_ERROR'


// SECTION - Local Setting
export const SET_LOCAL_SETTING_THEME_MODE = 'SET_LOCAL_SETTING_THEME_MODE'
export const SET_LOCAL_SETTING_THEME_SYSTEM_MODE = 'SET_LOCAL_SETTING_THEME_SYSTEM_MODE'
export const SET_LOCAL_SETTING_SETTINGS_TAB = 'SET_LOCAL_SETTING_SETTINGS_TAB'
export const SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW = 'SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW'
export const SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW = 'SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW'
export const SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD = 'SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD'
export const SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE = 'SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE'
export const SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE = 'SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE'
export const SET_LOCAL_SETTING_CALLS_FEEDBACK = 'SET_LOCAL_SETTING_CALLS_FEEDBACK'
export const SET_LOCAL_SETTING_ANALYTICS_TAB = 'SET_LOCAL_SETTING_ANALYTICS_TAB'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW'
export const SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS = 'SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS'
export const SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB = 'SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB'