var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.twoPartsView ? _c('div', {
    class: `aiTranscriptModal-twoParts-container AudioTranscriptContainer ${_vm.transcriptStatus == 3 ? '' : 'addScrollToIt'}`
  }, [_vm.transcriptStatus == 3 ? [_c('div', {
    staticClass: "aiTranscriptModal-twoParts-leftSide"
  }, [_vm.CallItemShow ? _c('Item1', {
    staticClass: "m-0 w-100",
    attrs: {
      "call": _vm.call
    }
  }) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-16px w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI call summary")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-descriptionText-13px mt-10px"
  }, [_vm._v(_vm._s(_vm.transcriptSummary))]), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI keywords")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "AI-Keywords-container"
  }, _vm._l(_vm.transcriptTags, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "AI-Keyword"
    }, [_vm._v(_vm._s(data))]);
  }), 0), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px w-100 d-flex align-items-center mt-10px"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("Sentiments")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "Sentiments-boxes"
  }, _vm._l(_vm.total_percentages, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "Sentiments-box"
    }, [_c('div', {
      staticClass: "Sentiments-box-heading"
    }, [_vm._v(_vm._s(item.type))]), _c('div', {
      staticClass: "newProgressBar"
    }, [_c('div', {
      style: `--value:${item.sum / _vm.transcriptTotalCount * 100};--fg:#249CE0`,
      attrs: {
        "role": "progressbar",
        "aria-valuenow": "65",
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }
    }, [_c('div', {
      staticClass: "newProgressBar-inner"
    }, [_vm._v(" " + _vm._s(_vm._f("to_fixed")(item.sum / _vm.transcriptTotalCount * 100, 0)) + "% ")])])])]);
  }), 0)]), _vm._t("transcript-end")], 2), _c('div', {
    staticClass: "aiTranscriptModal-twoParts-rightSide"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 withoutBorderRadius"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI transcript")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-descriptionText-subText-14px mt-12px"
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm.totalDuration / 60)) + " mint read")]), _c('div', {
    ref: "onlyScrollThis",
    staticClass: "TranscriptText-container"
  }, _vm._l(_vm.transcript, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "TranscriptText-itself speaker"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center w-100"
    }, [_c('div', {
      staticClass: "TranscriptText-heading"
    }, [_vm._v(_vm._s(data.channel_type))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText-13px"
    }, [_vm._v(_vm._s(_vm._f("to_fixed")(data.end - data.start)) + " s")])]), _c('p', {
      staticClass: "TranscriptText-text",
      class: `${_vm.currentTime > parseFloat(data.start) && _vm.currentTime < parseFloat(data.end) ? 'highlight' : ''} ${data.channel_type}`
    }, _vm._l(data.text.split(' '), function (word, wordIndex) {
      return _c('span', {
        key: wordIndex,
        class: {
          'highlight-word': _vm.currentTime >= data.start + wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2)
        }
      }, [_vm._v(" " + _vm._s(word) + " ")]);
    }), 0)]);
  }), 0)]), _c('div', {
    staticClass: "aiTranscriptModal-twoParts-rightSide-borderSection"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.soundFile,
      "design": 'Transcript'
    },
    on: {
      "currentTime": function ($event) {
        _vm.currentTime = $event;
        _vm.scrollView();
      }
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('div', {
          staticClass: "TranscriptAudio-downloadShareSection"
        }, [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover mr-32px",
          attrs: {
            "icon": "audioTranscript-share-icon",
            "width": "17.119px",
            "height": "18.826px"
          }
        }), _c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "16.2px",
            "height": "16.197px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3773142688)
  }), _c('div', {
    staticClass: "AudioTranscript-SpeakerPart mt-20px"
  }, _vm._l(_vm.speakers, function (speaker) {
    return _c('div', {
      key: speaker,
      staticClass: "SpeakerPart-item"
    }, [_c('div', {
      staticClass: "speaker-heading"
    }, [_vm._v(" " + _vm._s(speaker) + " ")]), _c('div', {
      staticClass: "d-flex timeline-container w-100 mt-10px"
    }, _vm._l(_vm.transcript, function (data, index) {
      return _c('div', {
        key: index,
        class: `${speaker == data.channel_type ? 'timeline' : ''} ${speaker}`,
        style: `
                    height: 10px;
                    width: ${(data.end - data.start) / _vm.transcriptTotalCount * 100}%;
                  `,
        attrs: {
          "something": (data.end - data.start) / _vm.transcriptTotalCount * 100
        },
        on: {
          "click": function ($event) {
            speaker == data.channel_type ? '' : '';
          }
        }
      });
    }), 0)]);
  }), 0)], 1)])] : _vm.api.fetch_transcript.send ? [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "mt-20px mb-20px w-50"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  }), 0)])] : _vm.transcriptStatus == 1 ? [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "mt-20px mb-20px w-50"
  }, [_c('vb-audio-player', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.soundFile,
      "design": 'NoTranscript'
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "14.2px",
            "height": "14.2px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })];
      },
      proxy: true
    }], null, false, 992160004)
  }), _c('div', {
    staticClass: "emptyScreenContainer mt-20px b-0 bg-transparent p-0"
  }, [_c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/audioTranscript.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-20px"
  }, [_vm._v("Unlock intelligent insights! 🎙️🔍")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Transcribe your call recordings effortlessly with our AI-powered transcription feature. Tap the button below, and watch as your audio transforms into actionable text. Elevate your communication experience now! ")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.call_recording_transcriptions ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recording_transcriptions
    },
    on: {
      "click": function ($event) {
        return _vm.makeTranscript();
      }
    }
  }, [_vm._v("Transcript recording with AI")])])])], 1)])] : _vm.transcriptStatus == 2 ? [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "mt-20px mb-20px w-50"
  }, [_c('p', {
    staticClass: "text-center processingText"
  }, [_vm._v("Processing")]), _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  })], 2)])] : _vm.transcriptStatus == 0 ? [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "mt-20px mb-20px w-50"
  }, [_c('div', {
    staticClass: "emptyScreenContainer mt-20px b-0 bg-transparent p-0"
  }, [_c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/audioTranscript.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-20px"
  }, [_vm._v("AI transcription is off! 🎙️🔍")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" If you want you want your call recordings to be AI transcribed, then turn this service on from number settings. ")])])])])] : _vm._e()], 2) : _c('div', {
    staticClass: "AudioTranscriptContainer"
  }, [_vm.transcriptStatus == 3 ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-16px w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI call summary")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-descriptionText-13px mt-10px"
  }, [_vm._v(_vm._s(_vm.transcriptSummary))]), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI keywords")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "AI-Keywords-container"
  }, _vm._l(_vm.transcriptTags, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "AI-Keyword"
    }, [_vm._v(_vm._s(data))]);
  }), 0)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main w-100 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-12px"
  }, [_vm._v("AI transcript")]), _c('vb-icon', {
    attrs: {
      "icon": "ai-brain-icon",
      "width": "14.184px",
      "height": "13.403px"
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-descriptionText-subText-14px mt-12px"
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm.totalDuration / 60)) + " mint read")]), _c('div', {
    ref: "onlyScrollThis",
    staticClass: "TranscriptText-container"
  }, _vm._l(_vm.transcript, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "TranscriptText-itself speaker"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center w-100"
    }, [_c('div', {
      staticClass: "TranscriptText-heading"
    }, [_vm._v(_vm._s(data.channel_type))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText-13px"
    }, [_vm._v(_vm._s(_vm._f("to_fixed")(data.end - data.start)) + " s")])]), _c('p', {
      staticClass: "TranscriptText-text",
      class: `${_vm.currentTime > parseFloat(data.start) && _vm.currentTime < parseFloat(data.end) ? 'highlight' : ''} ${data.channel_type}`
    }, _vm._l(data.text.split(' '), function (word, wordIndex) {
      return _c('span', {
        key: wordIndex,
        class: {
          'highlight-word': _vm.currentTime >= data.start + wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2)
        }
      }, [_vm._v(" " + _vm._s(word) + " ")]);
    }), 0)]);
  }), 0), _c('vb-audio-player', {
    attrs: {
      "src": _vm.soundFile,
      "design": 'Transcript'
    },
    on: {
      "currentTime": function ($event) {
        _vm.currentTime = $event;
        _vm.scrollView();
      }
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('div', {
          staticClass: "TranscriptAudio-downloadShareSection"
        }, [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover mr-32px",
          attrs: {
            "icon": "audioTranscript-share-icon",
            "width": "17.119px",
            "height": "18.826px"
          }
        }), _c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "16.2px",
            "height": "16.197px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3773142688)
  }), _c('div', {
    staticClass: "AudioTranscript-SpeakerPart mt-20px"
  }, _vm._l(_vm.speakers, function (speaker) {
    return _c('div', {
      key: speaker,
      staticClass: "SpeakerPart-item"
    }, [_c('div', {
      staticClass: "speaker-heading"
    }, [_vm._v(" " + _vm._s(speaker) + " ")]), _c('div', {
      staticClass: "d-flex timeline-container w-100 mt-10px"
    }, _vm._l(_vm.transcript, function (data, index) {
      return _c('div', {
        key: index,
        class: `${speaker == data.channel_type ? 'timeline' : ''} ${speaker}`,
        style: `
                  height: 10px;
                  width: ${(data.end - data.start) / _vm.transcriptTotalCount * 100}%;
                `,
        attrs: {
          "something": (data.end - data.start) / _vm.transcriptTotalCount * 100
        },
        on: {
          "click": function ($event) {
            speaker == data.channel_type ? '' : '';
          }
        }
      });
    }), 0)]);
  }), 0)], 1), _vm._t("transcript-end")] : _vm.api.fetch_transcript.send ? _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  }) : _vm.transcriptStatus == 1 ? _c('div', [_c('div', {
    staticClass: "mt-20px mb-20px"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.soundFile,
      "design": 'NoTranscript'
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "14.2px",
            "height": "14.2px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "emptyScreenContainer mt-20px b-0 bg-transparent p-0"
  }, [_c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/audioTranscript.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-20px"
  }, [_vm._v("Unlock intelligent insights! 🎙️🔍")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Transcribe your call recordings effortlessly with our AI-powered transcription feature. Tap the button below, and watch as your audio transforms into actionable text. Elevate your communication experience now! ")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.call_recording_transcriptions ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recording_transcriptions
    },
    on: {
      "click": function ($event) {
        return _vm.makeTranscript();
      }
    }
  }, [_vm._v("Transcript recording with AI")])])])], 1)]) : _vm.transcriptStatus == 2 ? _c('div', [_c('p', {
    staticClass: "text-center processingText"
  }, [_vm._v("Processing")]), _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  })], 2) : _vm.transcriptStatus == 0 ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "mt-20px mb-20px w-50 emptyScreenContainer-container"
  }, [_c('div', {
    staticClass: "emptyScreenContainer mt-20px b-0 bg-transparent p-0"
  }, [_c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/audioTranscript.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-20px"
  }, [_vm._v("AI transcription is off! 🎙️🔍")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" If you want you want your call recordings to be AI transcribed, then turn this service on from number settings. ")])])])])]) : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }