// SECTION - Pamarly
export const PAMARLY_LOGIN = 'PAMARLY_LOGIN'
export const PAMARLY_DATA_RESET = 'PAMARLY_DATA_RESET'

// SECTION - CRM
export const CRM_RESET = 'CRM_RESET'
export const CRM_LOGIN = 'CRM_LOGIN'
export const CRM_FETCH_PIPELINES = 'CRM_FETCH_PIPELINES'
export const CRM_FETCH_STORE_DATA = 'CRM_FETCH_STORE_DATA'
export const CRM_REFRESH_TOKEN = 'CRM_REFRESH_TOKEN'

// SECTION - CRM
export const RESET_API_ERRORS = 'RESET_API_ERRORS'
// export const RESET_CENTRIFUGO_ERRORS = 'RESET_CENTRIFUGO_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
