<template>
  <div :class="`dialer-analytics-main dialer-analytics-likeSomeThing ${conditions.full_data ? 'itsExpandedNow' : ''}`">
    <div class="dialer-box">
      <template v-if="$store.getters.getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Call activity</h1>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText ml-auto">
            <b-dropdown right>
              <template #button-content> 
                <b class="text-right">Options</b> 
                <div class="d-flex align-items-center">
                  <!-- <b-icon v-if="filters.voicemails.extensions.length>0" icon="x-circle-fill" varinat="#000000" class="ml-3" @click.stop="filters.voicemails.extensions=[]" /> -->
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                </div>
              </template>
              <b-dropdown-item @click="$modal.show(`${_uid}-ReportingFilterModal`)">Filter</b-dropdown-item>
              <b-dropdown-item @click="filters.reports.reset();">Reset filter</b-dropdown-item>
              <!-- <b-dropdown-item @click="$modal.show('someFullScreenModal')">View full screen</b-dropdown-item> -->
              <b-dropdown-item @click="conditions.full_data=!conditions.full_data">{{conditions.full_data ? 'Basic' : 'Expand'}}</b-dropdown-item>
              <b-dropdown-item :disabled="api.export_data.send" @click="exportData()">Export</b-dropdown-item>
            </b-dropdown>
          </div>
          <p class="dialer-settings-intro newerTextVersion mb-0">
            <span>Here’s where you can monitor calls and users.</span>
          </p>
        </header>
      </template>
      <template v-else>
        <h2 class="dialer-settings-title newer">
          Call activity
        </h2>
        <p class="dialer-settings-intro newerTextVersion mb-0">
          <span>Here’s where you can monitor calls and users.</span>
          <a @click="$emit('change-tab','Billing')" class="w-auto billedCallsLink" style="text-decoration: underline;font-size: 16px;color: black;cursor: pointer;">Billed calls</a>
        </p>
        <div class="d-flex w-100 justify-content-end mt-20px">
          <b-button variant="link" @click="$modal.show(`${_uid}-ReportingFilterModal`)">Filter</b-button>
          <b-button variant="link" @click="filters.reports.reset();">Reset filter</b-button>
          <b-button variant="link" @click="$modal.show('someFullScreenModal')">View full screen</b-button>
          <b-button variant="link" @click="conditions.full_data=!conditions.full_data">{{conditions.full_data ? 'Basic' : 'Expand'}}</b-button>
          <b-button :disabled="api.export_data.send" @click="exportData()" class="newButton">
            <vb-icon icon="squared-exportFile-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Export</span>
          </b-button>
        </div>
      </template>
      <div :class="`latestGreyBox-9-9-2023 pt-0 mt-20px border-top-0 ${conditions.full_data ? 'overflow-X-Table' : ''}`">
        <vb-table :horizontalScrollVersion="true" 
          class="latestTableDesign-withBlackBorders-again mt-0"
          :noRecordClass="`noDataInTable`"
          textNoRecord="No record found"
          :isListEmpty="response.reports.length==0"
          :loading="api.fetch_reports.send || is_loading" 
          :listLength="paginations.reports.total" 
          :perPage="paginations.reports.per_page" 
          @page-changed="paginations.reports.page=$event;fetchReports();"
          :conditions="{ 
            pagination: { 
              per_page: false 
            } 
          }"
          :page="paginations.reports.page"
        >
          <tr slot="header" class="w-100">
            <th class="border-0"><span>Call Type</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>Call Direction</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>User</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>team</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>ivr</span></th>
            <!-- <th class="border-0" v-if="conditions.full_data"><span>queue</span></th> -->
            <th class="border-0"><span>From </span></th>
            <th class="border-0"><span>To</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>customer number</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>Voip Business Number</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>In-call duration</span></th>
            <th class="border-0"><span>duration (total)</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>waiting time</span></th>  
            <th class="border-0"><span>recordings</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>country code</span></th>
            <!-- <th class="border-0" v-if="conditions.full_data"><span>customer country code</span></th> -->
            <th class="border-0" v-if="conditions.full_data"><span>answered</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>Date</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>call start time</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>call end time</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>extension</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>tags</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>notes</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>call quality</span></th>
            <th class="border-0" v-if="conditions.full_data"><span>call id</span></th>
          </tr>
          <template #body>
            <template v-if="is_loading">
              <tr v-for="n in 6" :key="n.id">
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="report in response.reports" :key="report.id">
                <!-- Call Type -->
                <td class="td-border-right">
                  <vb-icon class="mr-2" v-if="report.status=='Missed Call'" icon="analyticsReporting-missedCall-icon" height="13.922" width="13.922"/>
                  <vb-icon class="mr-2" v-else-if="report.status=='Received Call'" icon="analyticsReporting-receiveCall-icon" height="13.922" width="13.922"/>
                  <vb-icon class="mr-2" v-else icon="analyticsReporting-dialledCall-icon" height="13.922" width="13.922"/>
                  {{report.call_type | showDashIfNoValue}}
                  <br/>
                  <span v-if="!conditions.full_data">{{report.date | showTime('-',true)}}</span>
                </td>
                <!-- Call Direction -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.call_direction | showDashIfNoValue}}</td>
                <!-- User -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.user | showDashIfNoValue}}</td>
                <!-- team (not use) -->
                <td class="td-border-right" v-if="conditions.full_data">
                  {{report.team | showDashIfNoValue}}
                </td>
                <!-- ivr -->
                <td class="td-border-right" v-if="conditions.full_data">
                  {{report.ivr | showDashIfNoValue}}
                </td>
                <!-- queue -->
                <!-- <td class="td-border-right" v-if="conditions.full_data">
                  <Info :id="report.sourceAccountCode" :type="'call_queue'" :name="'-'" :not_show_on_empty="true" :is_blf="false" />
                </td> -->
                <!-- From -->
                <td class="td-border-right">{{report.from | showDashIfNoValue}}</td>
                <!-- To -->
                <td class="td-border-right">{{report.to | showDashIfNoValue}}</td>
                <!-- customer number -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.customer_number | showDashIfNoValue}}</td>
                <!-- Voip Business Number -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.voip_number | showDashIfNoValue}}</td>
                <!-- In-call duration -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.in_call_duration | showDashIfNoValue}}</td>
                <!-- duration (total) -->
                <td class="td-border-right">{{report.duration | showDashIfNoValue}}</td>
                <!-- waiting time -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.waiting_time | showDashIfNoValue}}</td>
                <!-- recordings -->
                <td class="call_history analyticsReportAudio td-border-right">
                  <div class="d-flex align-items-center" 
                    v-if="report.soundFile" 
                    v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                  >
                    <vb-audio-player :design="'minimum'"
                      :src="report.soundFile" 
                      :class="!getUserPermissions.call_recording ? 'for-disable' : ''" 
                      :disabled="!getUserPermissions.call_recording" 
                    />
                    <vb-icon 
                      icon="callAnalytics-reporting-download-icon" 
                      class="download_icon ml-3" 
                      height="17px" 
                      width="17px" 
                      style="cursor: pointer;" 
                      @click="api.download_audio.send || !getUserPermissions.call_recording ? '' : download(report.soundFile)" 
                      variant="primary"
                    />
                    <!-- <vb-icon 
                      icon="callAnalytics-reporting-download-icon" 
                      class="download_icon" 
                      height="17px" 
                      width="17px" 
                      style="cursor: pointer;" 
                      @click="!getUserPermissions.call_recording ? '' : $modal.show(`${_uid}-RecordingTranscript`,{
                        uniq_id: report.uniqueid,
                        sound_file: report.soundFile,
                      })" 
                      variant="primary"
                    /> -->
                    <vb-icon style="cursor: pointer;" icon="ai-brain-icon" class="ml-3" width="18.184px" height="17.403px" 
                      @click="!getUserPermissions.call_recording ? '' : $modal.show(`${_uid}-RecordingTranscript`,{
                        uniq_id: report.uniqueid,
                        sound_file: report.soundFile,
                      })" 
                    />
                  </div>
                  <template v-else>
                    <span class="text-center"> - </span>
                  </template>
                </td>
                <!-- country code -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.country_code | showDashIfNoValue}}</td>
                <!-- customer country code -->
                <!-- <td v-if="conditions.full_data">{{report.srcName | number_formater | getFullCountryCode}}</td> -->
                <!-- answered -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.answered}}</td>
                <!-- Date -->
                <td class="td-border-right" v-if="conditions.full_data">
                  <!-- {{report.date | showDashIfNoValue}} -->
                  {{report.date | showTime}}
                </td>
                <!-- call start time -->
                <!-- <td v-if="conditions.full_data">{{report.call_start_time}}</td> -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.call_start_time | showDashIfNoValue}}</td>
                <!-- call end time (not use) -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.call_end_time | showDashIfNoValue}}</td>
                <!-- extension -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.extension | showDashIfNoValue}}</td>
                <!-- tags -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.tags}}</td>
                <!-- notes -->
                <td class="td-border-right" v-if="conditions.full_data">{{report.notes}}</td>
                <!-- call quality -->
                <td class="td-border-right" v-if="conditions.full_data">
                  {{report.call_quality | callQualityValue}}
                  <b-icon icon="star-fill" variant="warning" />
                  <!-- <b-form-rating class="b-form-rating-inside-reporting" :value="report.call_quality" stars="5" disabled /> -->
                </td>
                <!-- call id -->
                <td v-if="conditions.full_data">{{report.call_id | showDashIfNoValue}}</td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 6" :key="n.id">
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
            </tr>
          </template>
        </vb-table>
      </div>
      <modal name="someFullScreenModal" class="someFullScreenModal">
        <div class="dialer-edit-header pt-0 pb-0 position-relative">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Call reporting detail</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newCloseButton" type="button" @click="$modal.hide('someFullScreenModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
        </div>
        <!-- <div class="latestGreyBox-9-9-2023">
          <div class="d-flex w-100">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp pr-2">
              <label>Call type</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.call_type"
                :options="Object.values(callTypes)"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('text','Select call type') }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.call_type='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('text') }}
                </template>
                <template #no-record>There is no date</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp px-2">
              <label>VoIP Business numbers</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.voip_number"
                :valueField="'did'"
                :textField="'did'"
                :options="voipnumbers"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('did','Select number') | number_formater }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.voip_number='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('did') | number_formater }}
                </template>
                <template #no-record>There is no number</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp pl-2">
              <label>Customer numbers</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.customer_number"
                :valueField="'did'"
                :textField="'did'"
                :options="voipnumbers"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('did','Select customer number') | number_formater }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.customer_number='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('did') | number_formater }}
                </template>
                <template #no-record>There is no customer number</template>
              </vb-select>
            </div>
          </div>
          <div class="d-flex w-100 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp pr-2">
              <label>Users</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.user"
                :options="users"
                textField="user.display_name"
                valueField="voipaccount"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center justify-content-between w-100 h-100">
                    <Info v-if="option" :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                    <span class="p-0" v-else>Select user</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.user='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  <Info :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                </template>
                <template #no-record>There is no user</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp px-2">
              <label>Teams</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.team"
                :options="teams"
                textField="name"
                valueField="real_id"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center justify-content-between w-100 h-100">
                    <Info v-if="option" :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                    <span class="p-0" v-else>Select team</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.team='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  <Info :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                </template>
                <template #no-record>There is no team</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp pl-2">
              <label>Number</label>
              <input class="w-100"
                type="text"
                v-model="filters.reports.search_number"
                v-vb-input-number-formate.not_start_plus
              />
              <p v-if="$v.filters.reports.search_number.$invalid" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.filters.reports.search_number.validNumber">Number is not valid</span>
              </p>
            </div>
          </div>
          <div class="d-flex w-100 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp pr-2">
              <label>Date</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.date"
                @change="filters.reports.start_date='';filters.reports.end_date='';"
                :options="Object.values(dates)"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('text','Select date') }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.date='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('text') }}
                </template>
                <template #no-record>There is no date</template>
              </vb-select>
            </div>
            <template v-if="filters.reports.date==dates.custom.value" >
              <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker wd-33-imp px-2">
                <label>Start date</label>
                <vue-ctk-date-time-picker 
                  v-bind="ctkDateTimePickerOptions" 
                  class="date-time-input"
                  :format="'YYYY-MM-DD hh:mm:ss a'"   
                  :formatted="'YYYY-MM-DD hh:mm:ss a'"
                  :noLabel="false"
                  v-model="filters.reports.start_date" 
                  @input="filters.reports.end_date=''"
                />
                <p v-if="$v.filters.reports.start_date.$invalid" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.filters.reports.start_date.required">Start date is required</span>
                </p>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker wd-33-imp pl-2">
                <label>End date</label>
                <vue-ctk-date-time-picker 
                  v-bind="ctkDateTimePickerOptions" 
                  class="date-time-input" 
                  :format="'YYYY-MM-DD hh:mm:ss a'"  
                  :formatted="'YYYY-MM-DD hh:mm:ss a'"
                  :noLabel="false"
                  :min-date="filters.reports.start_date" 
                  v-model="filters.reports.end_date" 
                />
                <p v-if="$v.filters.reports.end_date.$invalid" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.filters.reports.end_date.required">End date is required</span>
                </p>
              </div>
            </template>
          </div>
          <div class="d-flex justify-content-end w-100 mt-20px pr-2">
            <b-button @click="filters.reports.reset();" class="newButton mr-2">
              <vb-icon icon="squared-clearFilter-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Reset filter</span>
            </b-button>
          </div>
        </div> -->
        <div class="d-flex w-100 justify-content-end mt-20px">
          <b-button variant="link" @click="$modal.show(`${_uid}-ReportingFilterModal`)">Filter</b-button>
          <b-button variant="link" @click="filters.reports.reset();">Reset filter</b-button>
          <b-button :disabled="api.export_data.send" @click="exportData()" class="newButton">
            <vb-icon icon="squared-exportFile-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Export</span>
          </b-button>
        </div>
        <div class="overflow-X-Table latestGreyBox-9-9-2023 mt-20px">
          <vb-table :horizontalScrollVersion="true" 
            class="latestTableDesign-withBlackBorders-again mt-0"
            :noRecordClass="`noDataInTable`"
            textNoRecord="No record found"
            :isListEmpty="response.reports.length==0"
            :loading="api.fetch_reports.send || is_loading" 
            :listLength="paginations.reports.total" 
            :perPage="paginations.reports.per_page" 
            @page-changed="paginations.reports.page=$event;fetchReports();"
            :conditions="{ 
              pagination: { 
                per_page: false 
              } 
            }"
            :page="paginations.reports.page"
          >
            <tr slot="header" class="w-100">
              <th class="border-0"><span>Call Type</span></th>
              <th class="border-0"><span>Call Direction</span></th>
              <th class="border-0"><span>User</span></th>
              <th class="border-0"><span>team</span></th>
              <th class="border-0"><span>ivr</span></th>
              <th class="border-0"><span>From </span></th>
              <th class="border-0"><span>To</span></th>
              <th class="border-0"><span>customer number</span></th>
              <th class="border-0"><span>Voip Business Number</span></th>
              <th class="border-0"><span>In-call duration</span></th>
              <th class="border-0"><span>duration (total)</span></th>
              <th class="border-0"><span>waiting time</span></th>  
              <th class="border-0"><span>recordings</span></th>
              <th class="border-0"><span>country code</span></th>
              <th class="border-0"><span>answered</span></th>
              <th class="border-0"><span>Date</span></th>
              <th class="border-0"><span>call start time</span></th>
              <th class="border-0"><span>call end time</span></th>
              <th class="border-0"><span>extension</span></th>
              <th class="border-0"><span>tags</span></th>
              <th class="border-0"><span>notes</span></th>
              <th class="border-0"><span>call quality</span></th>
              <th class="border-0"><span>call id</span></th>
            </tr>
            <template #body>
              <template v-if="is_loading">
                <tr v-for="n in 6" :key="n.id">
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                  <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="report in response.reports" :key="report.id">
                  <!-- Call Type -->
                  <td class="td-border-right">
                    <vb-icon class="mr-2" v-if="report.status=='Missed Call'" icon="analyticsReporting-missedCall-icon" height="13.922" width="13.922"/>
                    <vb-icon class="mr-2" v-else-if="report.status=='Received Call'" icon="analyticsReporting-receiveCall-icon" height="13.922" width="13.922"/>
                    <vb-icon class="mr-2" v-else icon="analyticsReporting-dialledCall-icon" height="13.922" width="13.922"/>
                    {{report.call_type | showDashIfNoValue}}
                  </td>
                  <!-- Call Direction -->
                  <td class="td-border-right">{{report.call_direction | showDashIfNoValue}}</td>
                  <!-- User -->
                  <td class="td-border-right">{{report.user | showDashIfNoValue}}</td>
                  <!-- team (not use) -->
                  <td class="td-border-right">
                    {{report.team | showDashIfNoValue}}
                  </td>
                  <!-- ivr -->
                  <td class="td-border-right">
                    {{report.ivr | showDashIfNoValue}}
                  </td>
                  <!-- From -->
                  <td class="td-border-right">{{report.from | showDashIfNoValue}}</td>
                  <!-- To -->
                  <td class="td-border-right">{{report.to | showDashIfNoValue}}</td>
                  <!-- customer number -->
                  <td class="td-border-right">{{report.customer_number | showDashIfNoValue}}</td>
                  <!-- Voip Business Number -->
                  <td class="td-border-right">{{report.voip_number | showDashIfNoValue}}</td>
                  <!-- In-call duration -->
                  <td class="td-border-right">{{report.in_call_duration | showDashIfNoValue}}</td>
                  <!-- duration (total) -->
                  <td class="td-border-right">{{report.duration | showDashIfNoValue}}</td>
                  <!-- waiting time -->
                  <td class="td-border-right">{{report.waiting_time | showDashIfNoValue}}</td>
                  <!-- recordings -->
                  <td class="call_history analyticsReportAudio td-border-right">
                    <div class="d-flex align-items-center" 
                      v-if="report.soundFile" 
                      v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                    >
                      <vb-audio-player :design="'minimum'"
                        :src="report.soundFile" 
                        :class="!getUserPermissions.call_recording ? 'for-disable' : ''" 
                        :disabled="!getUserPermissions.call_recording" 
                      />
                      <vb-icon 
                        icon="callAnalytics-reporting-download-icon" 
                        class="download_icon ml-3" 
                        height="17px" 
                        width="17px" 
                        style="cursor: pointer;" 
                        @click="api.download_audio.send || !getUserPermissions.call_recording ? '' : download(report.soundFile)" 
                        variant="primary"
                      />
                      <vb-icon style="cursor: pointer;" icon="ai-brain-icon" class="ml-3" width="18.184px" height="17.403px" 
                        @click="!getUserPermissions.call_recording ? '' : $modal.show(`${_uid}-RecordingTranscript`,{
                          uniq_id: report.uniqueid,
                          sound_file: report.soundFile,
                        })" 
                      />
                    </div>
                    <template v-else>
                      <span class="text-center"> - </span>
                    </template>
                  </td>
                  <!-- country code -->
                  <td class="td-border-right">{{report.country_code | showDashIfNoValue}}</td>
                  <!-- answered -->
                  <td class="td-border-right">{{report.answered}}</td>
                  <!-- Date -->
                  <td class="td-border-right">{{report.date | showDashIfNoValue}}</td>
                  <!-- call start time -->
                  <td class="td-border-right">{{report.call_start_time | showDashIfNoValue}}</td>
                  <!-- call end time (not use) -->
                  <td class="td-border-right">{{report.call_end_time | showDashIfNoValue}}</td>
                  <!-- extension -->
                  <td class="td-border-right">{{report.extension | showDashIfNoValue}}</td>
                  <!-- tags -->
                  <td class="td-border-right">{{report.tags}}</td>
                  <!-- notes -->
                  <td class="td-border-right">{{report.notes}}</td>
                  <!-- call quality -->
                  <td class="td-border-right">
                    {{report.call_quality | callQualityValue}}
                    <b-icon icon="star-fill" variant="warning" />
                  </td>
                  <!-- call id -->
                  <td>{{report.call_id | showDashIfNoValue}}</td>
                </tr>
              </template>
            </template>
            <template #loading>
              <tr v-for="n in 6" :key="n.id">
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td v-if="conditions.full_data"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
                <td><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
              </tr>
            </template>
          </vb-table>
        </div>
      </modal>
      <modal :name="`${_uid}-ReportingFilterModal`" class="callReportingFilterModal">
        <div class="dialer-edit-header pt-0 position-relative">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Filters</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-ReportingFilterModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="d-flex w-100">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2">
              <label>Date</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.date"
                @change="filters.reports.start_date='';filters.reports.end_date='';"
                :options="Object.values(dates)"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('text','Select date') }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.date='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('text') }}
                </template>
                <template #no-record>There is no date</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
              <label>Call type</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.call_type"
                :options="Object.values(callTypes)"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('text','Select call type') }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.call_type='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('text') }}
                </template>
                <template #no-record>There is no date</template>
              </vb-select>
            </div>
          </div>
          <div v-if="filters.reports.date==dates.custom.value" class="d-flex w-100 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder subSectionLeftAligned VueCtkDateTimePicker w-50 pr-2">
              <label>Start date</label>
              <vue-ctk-date-time-picker 
                v-bind="ctkDateTimePickerOptions" 
                class="date-time-input"
                :format="'YYYY-MM-DD hh:mm:ss a'"   
                :formatted="'YYYY-MM-DD hh:mm:ss a'"
                :noLabel="false"
                v-model="filters.reports.start_date" 
                @input="filters.reports.end_date=''"
                id="start_time" name="start_time"
              />
              <p v-if="$v.filters.reports.start_date.$invalid" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.filters.reports.start_date.required">Start date is required</span>
              </p>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker w-50 pl-2">
              <label>End date</label>
              <vue-ctk-date-time-picker 
                v-bind="ctkDateTimePickerOptions" 
                class="date-time-input" 
                :format="'YYYY-MM-DD hh:mm:ss a'"  
                :formatted="'YYYY-MM-DD hh:mm:ss a'"
                :noLabel="false"
                :min-date="filters.reports.start_date" 
                v-model="filters.reports.end_date" 
                id="end_time" name="end_time"
              />
              <p v-if="$v.filters.reports.end_date.$invalid" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.filters.reports.end_date.required">End date is required</span>
              </p>
            </div>
          </div>
          <div class="d-flex w-100 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2">
              <label>VoIP Business numbers</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.voip_number"
                :valueField="'did'"
                :textField="'did'"
                :options="voipnumbers"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center w-100 h-100">
                    <span class="p-0">{{ option | get_property('did','Select number') | number_formater }}</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.voip_number='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  {{ option | get_property('did') | number_formater }}
                </template>
                <template #no-record>There is no number</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
              <label>Number</label>
              <input class="w-100"
                type="text"
                v-model="filters.reports.search_number"
                v-vb-input-number-formate.not_start_plus
              />
              <p v-if="$v.filters.reports.search_number.$invalid" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.filters.reports.search_number.validNumber">Number is not valid</span>
              </p>
            </div>
          </div>
          <div class="d-flex w-100 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2">
              <label>Users</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.user"
                :options="users"
                textField="user.display_name"
                valueField="voipaccount"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center justify-content-between w-100 h-100">
                    <Info v-if="option" :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                    <span class="p-0" v-else>Select user</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.user='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  <Info :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                </template>
                <template #no-record>There is no user</template>
              </vb-select>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
              <label>Teams</label>
              <vb-select 
                class="w-100"
                v-model="filters.reports.team"
                :options="teams"
                textField="name"
                valueField="real_id"
              >
                <template #selected-option="{ option }">
                  <div class="d-flex align-content-center justify-content-between w-100 h-100">
                    <Info v-if="option" :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                    <span class="p-0" v-else>Select team</span>
                    <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                      <b-icon icon="x" scale="1.5" @click="filters.reports.team='';" />
                    </b-button>
                  </div>
                </template>
                <template #option="{ option }">
                  <Info :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                </template>
                <template #no-record>There is no team</template>
              </vb-select>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <RecordingTranscriptModal :modalName="`${_uid}-RecordingTranscript`" />
  </div>
</template>

<script>
import { $fn, VOIP_API } from '@/utils'
import { getFullCountryCode } from '@/filter'
import { mapGetters, mapState } from 'vuex'
import RecordingTranscriptModal from '../Modals/RecordingTranscriptModal.vue';
import Info from '../Lists/Info.vue';
import moment from 'moment';
import _ from 'lodash';
import { ctkDateTimePicker } from '@/mixin';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
const call_types = {
  inbound_abandoned: { 
    text: 'Inbound - abandoned', 
    value: 'inbound_abandoned' 
  },
  inbound_missed: { 
    text: 'Inbound - missed', 
    value: 'inbound_missed'
  },
  inbound_answered: { 
    text: 'Inbound - answered', 
    value: 'inbound_answered'
  },
  outbound: { 
    text: 'Outbound', 
    value: 'outbound' 
  },
}
const dates = {
  today: { 
    text: 'Today', 
    value: 'today', 
  },
  yesterday: { 
    text: 'Yesterday', 
    value: 'yesterday' 
  },
  ten_days: { 
    text: 'Last 10 days', 
    value: 'ten_days' 
  },
  twentyfive_days: { 
    text: 'Last 25 days', 
    value: 'twentyfive_days' 
  },
  thirty_days: { 
    text: 'Last 30 days', 
    value: 'thirty_days' 
  },
  fifty_days: { 
    text: 'Last 50 days', 
    value: 'fifty_days' 
  },
  custom: { 
    text: 'Custom', 
    value: 'custom' 
  },
}
export default {
  name: "Something2",
  mixins: [
    ctkDateTimePicker,
  ],
  components: {
    RecordingTranscriptModal,
    Info,
  },
  data() {
    return {
      api: {
        fetch_reports: this.$helperFunction.apiInstance({
          source: true,
        }),
        download_audio: this.$helperFunction.apiInstance(),
        export_data: this.$helperFunction.apiInstance(),
      },
      filters: {
        reports: this.$helperFunction.stateInstance({
          data: {
            voip_number: '',
            customer_number: '',
            user: '',
            team: '',
            call_type: '',
            date: '',
            start_date: '',
            end_date: '',
            status: '',
            billSec: '',
            search_number: '',
          }
        }),
      },
      response: {
        reports: [],
      },
      paginations: {
        reports: {
          page: 1,
          per_page: 15,
          total: 0,
        },
      },
      conditions: {
        full_data: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      users: (state)=>Object.values(state.common.voipusers ?? {}),
      teams: (state)=>state.common.teams?.data ?? [],
      voipnumbers: (state)=>state.common.numbers ?? [],
    }),
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
    ]),
    callTypes(){ return call_types },
    dates(){ return dates },
    reportsFilters(){
      return {
        change_values: {
          call_type: this.filters.reports.call_type,
          date: this.filters.reports.date,
        },
        api_call: {
          start_date: this.filters.reports.start_date,
          end_date: this.filters.reports.end_date,
          status: this.filters.reports.status,
          billSec: this.filters.reports.billSec,
          customer_number: this.filters.reports.customer_number,
          team: this.filters.reports.team,
          user: this.filters.reports.user,
          voip_number: this.filters.reports.voip_number,
          search_number: this.filters.reports.search_number,
        },
      }
    },
    is_loading(){
      return this.api.fetch_reports.send
    }
  },
  validations: {
    filters: {
      reports: {
        voip_number: {
          
        },
        customer_number: {
          
        },
        user: {
          
        },
        team: {
          
        },
        date: {
          
        },
        start_date: {
          required: requiredIf(function(){ return this.filters.reports.date })
        },
        end_date: {
          required: requiredIf(function(){ return this.filters.reports.date })
        },
        call_type: {
          
        },
        status: {
          
        },
        billSec: {
          
        },
        search_number: {
          validNumber(value){ return !value || $fn.validPhoneNumber(value) },
        },
      },
    },
  },
  filters: {
    getFullCountryCode: getFullCountryCode,
    callQualityValue(value){
      return parseInt(value)
    },
    show_number(report,source=false){
      if(report.status=='Dialed Call') {
        if(source) return report.srcName
        else return report.dstName
      } else {
        if(source) return report.dstName
        else return report.srcName
      }
    },
    callDirectionText(value){
      if(value=='Dialed Call') return 'outbound'
      else return 'inbound'
    },
    callTypeText(report){
      if(report.status=='Dialed Call') return 'Outbound'
      // else if(report.status=='Missed Call' && report.billSec!='00:00:00') return 'Inbound - Missed'
      else if(report.status=='Missed Call') return 'Inbound - Missed'
      else if(report.disposition=='ANSWERED') return 'Inbound - Answered'
      else if(report.status=='Received Call' && report.disposition!='ANSWERED') return 'Inbound - Abandoned'
      else return 'inbound'
    },
    tagsText(value){
      const tags = value?.filter?.(i=>i.type=='tag' && i?.tag?.tag) ?? []
      return tags.map(i=>i.tag.tag).join(', ') || '-'
      // return tags.map(i=>i.tag.tag).join(', ')
    },
    noteText(value){
      const note = value?.find?.(i=>i.type=='note' && i?.log)
      // return note.length > 0 ? tags.map(i=>i.tag.tag).join(', ') : '-'
      return note?.log ?? '-'
    },
    answeredText(value){
      if(value.billSec=='00:00:00') return 'No'
      else return 'Yes'
    },
    showDashIfNoValue(value){
      return value || '-'
    },
    showTime(value,dv='-',format=''){
      if (!value) return dv;
      // return moment.unix(value).format("hh:mm:ss a , MMMM DD YYYY ");
      return moment.unix(value).format(format ? "hh:mm:ss" : "YYYY-MM-DD hh:mm:ss");
    },
  },
  watch: {
    "reportsFilters.change_values": {
      deep: true,
      handler(reports_filter){
        if(reports_filter.date==dates.today.value) {
          this.filters.reports.start_date=moment().startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        } else if(reports_filter.date==dates.yesterday.value) {
          this.filters.reports.start_date=moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        } else if(reports_filter.date==dates.ten_days.value) {
          this.filters.reports.start_date=moment().subtract(10, 'day').startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        } else if(reports_filter.date==dates.twentyfive_days.value) {
          this.filters.reports.start_date=moment().subtract(25, 'day').startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        } else if(reports_filter.date==dates.thirty_days.value) {
          this.filters.reports.start_date=moment().subtract(30, 'day').startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        } else if(reports_filter.date==dates.fifty_days.value) {
          this.filters.reports.start_date=moment().subtract(50, 'day').startOf('day').format('YYYY-MM-DD hh:mm:ss a');
          this.filters.reports.end_date=moment().endOf('day').format('YYYY-MM-DD hh:mm:ss a');
        }
        if(reports_filter.call_type==call_types.outbound.value) {
          this.filters.reports.status='Dialed Call'
          this.filters.reports.billSec=''
        } else if(reports_filter.call_type==call_types.inbound_missed.value) {
          this.filters.reports.status='Missed Call'
          this.filters.reports.billSec=''
        } else if(reports_filter.call_type==call_types.inbound_abandoned.value) {
          this.filters.reports.status='Received Call'
          this.filters.reports.billSec='00:00:00'
        } else if(reports_filter.call_type==call_types.inbound_answered.value) {
          this.filters.reports.status=''
          this.filters.reports.billSec='!00:00:00'
        } else {
          this.filters.reports.status=''
          this.filters.reports.billSec=''
        }
      },
    },
    "reportsFilters.api_call": {
      deep: true,
      handler: _.debounce(function(){
        this.$v.filters.reports.$touch()
        if(this.$v.filters.reports.$invalid) return;
        this.paginations.reports.page=1
        this.fetchReports()
      }, 2*1000),
    },
  },
  methods: {
    async exportData() {
      if(this.api.export_data.send) return;
      try {
        this.api.export_data.send=true
        const search_number = $fn.getNumberFormated(this.filters.reports.search_number)
        const { data } = await VOIP_API.endpoints.call_activity.reportingfilterdataDownload({
          type: "data",
          page: this.paginations.reports.page,
          start_date: this.filters.reports.start_date,
          end_date: this.filters.reports.end_date,
          status: this.filters.reports.status,
          billSec: this.filters.reports.billSec,
          customer_number: this.filters.reports.customer_number,
          team: this.filters.reports.team,
          user: this.filters.reports.user,
          voip_number: this.filters.reports.voip_number,
          search_number: search_number,
          is_export: '1',
        })
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `reporting - ${moment().format('DD MMM YYYY hh_mm_ss a')}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.export_data.send=false
      }
    },
    async fetchReports() {
      this.api.fetch_reports.source?.cancel?.("Requesting again");
      this.api.fetch_reports.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_reports.send=true
        const search_number = $fn.getNumberFormated(this.filters.reports.search_number)
        const { data } = await VOIP_API.endpoints.call_activity.reportingfilterdata({
          type: "data",
          page: this.paginations.reports.page,
          start_date: this.filters.reports.start_date,
          end_date: this.filters.reports.end_date,
          status: this.filters.reports.status,
          billSec: this.filters.reports.billSec,
          customer_number: this.filters.reports.customer_number,
          team: this.filters.reports.team,
          user: this.filters.reports.user,
          voip_number: this.filters.reports.voip_number,
          search_number: search_number,
        },this.api.fetch_reports.source.token)
        this.response.reports=data?.data ?? []
        this.paginations.reports.total=data?.total ?? 0
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_reports.send=false
      }
    },
    async download(url) {
      if(this.api.download_audio.send || !url) return;
      try {
        this.api.download_audio.send=true
        const response = await fetch(url)
        const blob = await response.blob()
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.download_audio.send=false
      }
    },
  },
  mounted(){
    this.fetchReports()
  },
}
</script>

<style>

</style>