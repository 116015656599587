<template>
  <div v-if="condition.pagination.show" class="dialer-paginationRow">
    <div class="dialer-pagination" v-if="!isListEmpty && listLength > pagination.per_page">
      <div v-if="condition.pagination.first" class="icon previous-icon fastPrevious-icon" :disabled="pagination.page === 1" @click="pagination.page = 1">
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
      </div>
      <div v-if="condition.pagination.prev" class="icon previous-icon" :disabled="pagination.page === 1" @click="pagination.page - 1 !== 0 ? (pagination.page = pagination.page - 1) : ''">
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
      </div>
      <div v-for="(n, index) in totalArray" :key="index" :class="`icon ${n == pagination.page ? 'active' : ''} ${n == '...' ? 'dotted-pagination' : ''}`" @click="n != '...' ? (pagination.page = n) : ''">{{ n }}</div>
      <div v-if="condition.pagination.next" class="icon forward-icon" :disabled="pagination.page === total" @click="pagination.page + 1 > total ? '' : (pagination.page = pagination.page + 1)">
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
      </div>
      <div v-if="condition.pagination.last" class="icon forward-icon fastForward-icon" :disabled="pagination.page === total" @click="pagination.page = total">
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
        <vb-icon icon="dialer-arrowDown-icon" width="13.825" height="8.326" />
      </div>
    </div>
    <div v-if="condition.pagination.per_page && !isListEmpty && !isEmpty(numberListOptions)" class="dialer-recordPerList">
      <span class="md-mar-right">Result per page</span>
      <div class=" whiteBGinputWithGreyRoundedBorder dropdownVersion whiteBg-select paginationC simple-image">
        <vb-select :selected="pagination.per_page" @change="pagination.per_page = $event" :options="numberListOptions" class="dialer-dropDown-onRight" />
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
export default {
  name: 'vb-pagination',
  props: {
    listLength: {
      type: Number,
      default: 0,
    },
    page: {
      type: [String,Number],
      default: 1,
    },
    conditions: {
      type: Object,
      default: () => ({}),
    },
    perPage: {
      type: Number,
      default: 25
    },
    isListEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        get start() {
          return this.end - this.per_page + 1;
        },
        get end() {
          return this.page * this.per_page - 1;
        },
        page: 1,
        per_page: 5,
      },
    };
  },
  inject:['isEmpty'],
  computed: {
    total() { return Math.ceil(this.listLength / this.pagination.per_page); },
    totalArray() {
      var total = this.total;
      var cur = this.pagination.page;
      if (total > 1) {
        var array = [];
        if (cur > 2) {
          array.unshift(cur - 1);
        }
        if (cur > 1 && cur < total) {
          array.push(cur);
        }
        if (cur + 2 < total) {
          array.push(cur + 1);
          // array.push(cur + 2);
        } else if (cur + 1 < total) {
          array.push(cur + 1);
        }
        if (cur > 3) {
          array.unshift("...");
        }
        array.unshift(1);
        if (total - 4 >= cur) {
          array.push("...");
        }
        array.push(total);
        return array;
      } else if (total === 1) {
        return [total];
      } else {
        return [];
      }
    },
    condition() {
      return {
        pagination: {
          show: this.conditions?.pagination?.show ?? true,
          last: this.conditions?.pagination?.last ?? true,
          first: this.conditions?.pagination?.first ?? true,
          next: this.conditions?.pagination?.next ?? true,
          prev: this.conditions?.pagination?.prev ?? true,
          per_page: this.conditions?.pagination?.per_page ?? true,
        },
      };
    },
    numberListOptions() {
      const array = [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ];
      return array
      // let show = 100;
      // if (this.listLength <= 5) {
      //   show = 0;
      // } else if (this.listLength > 5 && this.listLength <= 10) {
      //   show = 10;
      // } else if (this.listLength > 10 && this.listLength <= 25) {
      //   show = 25;
      // } else if (this.listLength > 25 && this.listLength <= 50) {
      //   show = 50;
      // } else {
      //   show = 100;
      // }
      // return array.filter((item) => item.value <= show);
    },
    jsonPagination(){
      return JSON.stringify(this.pagination)
    },
  },
  watch: {
    total(total) {
      if (this.pagination.page > total) {
        this.pagination.page = total || 1;
      }
    },
    "pagination.page"(new_page,old_page){
      if(!old_page) return;
      if(new_page==old_page) return;
      this.$emit('page-changed',new_page)
    },
    "pagination.per_page"(new_page,old_page){
      if(!old_page) return;
      if(new_page==old_page) return;
      this.$emit('per-page-changed',new_page)
    },
    perPage(perPage) {
      this.pagination.per_page = perPage;
    },
    page(page) {
      this.pagination.page = page;
    },
    jsonPagination(json_pagination){
      const pagination = JSON.parse(json_pagination)
      this.$emit('latest-pagination-state',pagination)
    }
  },
  mounted() {
    this.pagination.page = this.page;
    this.pagination.per_page = this.perPage;
  },
}
</script>

<style>
.dialer-input-field.input-consistent-inner-shadows.whiteBg-select.paginationC.simple-image{
  width:auto;
}
.dialer_main_body .dialer_main_body_inner .dialer_inner_right_side .trustedDevicesTable-container .dialer-input-field.input-consistent-inner-shadows.whiteBg-select.paginationC.simple-image button{
  background: #f8f8f8 !important;
}
</style>