var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section ActivitySection"
  }, [_c('TopBar', {
    attrs: {
      "title": 'Activities'
    }
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-CreateActivity`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v("Create Activities")])], 1), _c('div', {
    staticClass: "crm-main-dd dropdownVersion filterDD ml-16px"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Import")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-export`);
      }
    }
  }, [_vm._v("Export")]), _c('b-dropdown-item', [_vm._v("Trashed")]), _c('b-dropdown-item', [_vm._v("List Settings")])], 1)], 1)]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_vm.api.fetch_activities_settings.send ? _c('div', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "40px",
      "width": "20%"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "latestShimmerDesign mt-20px",
    staticStyle: {
      "height": "30px",
      "width": "100%"
    }
  }), _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign mt-10px",
      staticStyle: {
        "height": "30px",
        "width": "100%"
      }
    });
  })], 2) : [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_vm.filters.activities.activity_type_id ? _c('div', {
    staticClass: "mr-16px",
    on: {
      "click": function ($event) {
        _vm.filters.activities.activity_type_id = '';
        _vm.fetchActivities();
      }
    }
  }, [_c('b', [_vm._v("All")])]) : _vm._e(), _vm._l(_vm.activityTypes, function (activity_type) {
    return _c('div', {
      key: activity_type.id,
      staticClass: "newCloseButton onlyIcon mr-2",
      class: {
        active: _vm.filters.activities.activity_type_id == activity_type.id
      },
      on: {
        "click": function ($event) {
          _vm.filters.activities.activity_type_id = activity_type.id;
          _vm.fetchActivities();
        }
      }
    }, [_c('vb-icon', {
      class: `${activity_type.name == 'Task' ? 'squared-done-icon' : ''}`,
      attrs: {
        "icon": _vm._f("activityTypeIcons")(activity_type),
        "height": "18px",
        "width": "18px"
      }
    })], 1);
  })], 2), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "w-100 d-flex"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_vm.selectedActivityFilter ? [_c('span', [_vm._v(_vm._s(_vm.selectedActivityFilter.name))])] : [_c('vb-icon', {
          staticClass: "crm-filterOptions-icon",
          attrs: {
            "icon": "crm-filterOptions-icon"
          }
        }), _c('span', [_vm._v("Filter")])], _c('b-icon', {
          staticClass: "ml-2",
          attrs: {
            "icon": "chevron-down"
          }
        })], 2)];
      },
      proxy: true
    }])
  }, [_vm.filters.activities.filter_id ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filters.activities.filter_id = '';
        _vm.fetchActivities();
      }
    }
  }, [_vm._v("Clear Filter")]) : _vm._e(), _vm._l(_vm.activityFilters, function (filter) {
    return _c('b-dropdown-item', {
      key: filter.id,
      on: {
        "click": function ($event) {
          _vm.filters.activities.filter_id = filter.id;
          _vm.fetchActivities();
        }
      }
    }, [_vm.filters.activities.filter_id == filter.id ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(filter.name))])], 1);
  })], 2)], 1), _c('button', {
    staticClass: "newButton onlyIcon ml-8px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-pencil-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "totalsTextOnMain minWidth-maxContent mr-16px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.activities, 'meta.total', 0)) + " Activity")]), _c('div', {
    staticClass: "crm-input-container mr-3 mb-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.activities.query,
      expression: "filters.activities.query"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.filters.activities.query
    },
    on: {
      "change": function ($event) {
        return _vm.fetchActivities();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.activities, "query", $event.target.value);
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1)])]), _c('div', {
    staticClass: "overflow-X-Table latestGreyBox-9-9-2023 pt-0 mt-20px border-top-0 mt-20px"
  }, [_c('vb-table', {
    staticClass: "mt-0 latestTableDesign-withBlackBorders-again",
    attrs: {
      "horizontalScrollVersion": true,
      "loading": _vm.api.fetch_activities_settings.send || _vm.api.fetch_activities.send,
      "isListEmpty": _vm.activities.length == 0,
      "listLength": _vm.activities.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('tr', [_c('th', [_c('b-form-checkbox', {
          staticClass: "crm-checkbox",
          attrs: {
            "disabled": _vm.api.action.send,
            "checked": _vm.activities.every(function (i) {
              return _vm.selected.activities.includes(i.id);
            })
          },
          on: {
            "change": function ($event) {
              _vm.activities.every(function (i) {
                return _vm.selected.activities.includes(i.id);
              }) ? _vm.selected.activities = [] : _vm.selected.activities = _vm.activities.map(function (i) {
                return i.id;
              });
            }
          }
        })], 1), _vm._l(_vm.columns, function (column, index) {
          return _c('th', {
            key: column.attribute,
            staticClass: "dialer-has-sort",
            staticStyle: {
              "width": "max-content"
            }
          }, [index == 0 && _vm.selected.activities.length > 0 ? [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion"
          }, [_c('b-dropdown', {
            attrs: {
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('span', {
                  staticClass: "d-flex align-items-center p-0"
                }, [_c('vb-icon', {
                  staticClass: "crm-filterOptions-icon",
                  attrs: {
                    "icon": "crm-filterOptions-icon"
                  }
                }), _c('span', [_vm._v("Select Action (" + _vm._s(_vm.selected.activities.length) + " record)")]), _c('b-icon', {
                  staticClass: "ml-2",
                  attrs: {
                    "icon": "chevron-down"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, _vm._l(_vm.activityActions, function (data) {
            return _c('b-dropdown-item', {
              key: data.uriKey,
              on: {
                "click": function ($event) {
                  _vm.selected.action = data;
                  data.withoutConfirmation ? _vm.action() : _vm.$modal.show(`${_vm._uid}-action`);
                }
              }
            }, [_c('span', [_vm._v(_vm._s(data.name))])]);
          }), 1)], 1)] : _c('span', {
            staticClass: "dialer-sorted-by ascending",
            staticStyle: {
              "width": "max-content"
            }
          }, [_vm._v(_vm._s(column.label))])], 2);
        })], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return _vm._l(_vm.activities, function (activity) {
          return _c('tr', {
            key: activity.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "disabled": _vm.api.remove.send.includes(activity.id) || _vm.api.action.send,
              "checked": _vm.selected.activities.includes(activity.id)
            },
            on: {
              "change": function ($event) {
                _vm.selected.activities.includes(activity.id) ? _vm.selected.activities.splice(_vm.selected.activities.indexOf(activity.id), 1) : _vm.selected.activities.push(activity.id);
              }
            }
          })], 1), _vm._l(_vm.columns, function (column, index) {
            return _c('td', {
              key: column.attribute,
              staticStyle: {
                "width": "max-content"
              }
            }, [_c('div', {
              class: `d-flex align-items-center justify-content-between`,
              style: `width: ${index == 0 ? '100%' : 'max-content'}`
            }, [column.attribute == 'title' ? [_vm._v(_vm._s(activity.title))] : column.attribute == 'type' ? [_vm._v(_vm._s(activity.type.name))] : column.attribute == 'due_date' ? [_vm._v(_vm._s(activity.displayAs.due_date))] : column.attribute == 'end_date' ? [_vm._v(_vm._s(activity.displayAs.end_date))] : column.attribute == 'owner_assigned_date' ? [_vm._v(_vm._s(activity.displayAs.owner_assigned_date))] : column.attribute == 'note' ? [_vm._v(_vm._s(activity.note))] : column.attribute == 'creator' ? [_vm._v(_vm._s(activity.creator.name))] : column.attribute == 'reminded_at' ? [_vm._v(_vm._s(activity.displayAs.reminded_at))] : column.attribute == 'completed_at' ? [_vm._v(_vm._s(activity.displayAs.completed_at))] : column.attribute == 'updated_at' ? [_vm._v(_vm._s(activity.displayAs.updated_at))] : column.attribute == 'created_at' ? [_vm._v(_vm._s(activity.displayAs.created_at))] : column.attribute == 'deals' ? [_vm._v(_vm._s(activity.deals))] : column.attribute == 'contacts' ? [_vm._v(_vm._s(activity.contacts))] : column.attribute == 'companies' ? [_vm._v(_vm._s(activity.companies))] : column.attribute == 'id' ? [_vm._v(_vm._s(activity.id))] : _vm._e(), index == 0 ? _c('div', {
              staticClass: "ml-auto whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD w-fit-content"
            }, [_c('b-dropdown', {
              attrs: {
                "no-caret": ""
              },
              scopedSlots: _vm._u([{
                key: "button-content",
                fn: function () {
                  return [_c('b-icon', {
                    attrs: {
                      "icon": "three-dots-vertical"
                    }
                  })];
                },
                proxy: true
              }], null, true)
            }, [_c('b-dropdown-item', {
              attrs: {
                "disabled": _vm.api.remove.send.includes(activity.id)
              },
              on: {
                "click": function ($event) {
                  return _vm.remove(activity);
                }
              }
            }, [_c('span', [_vm._v("Delete")])])], 1)], 1) : _vm._e()], 2)]);
          })], 2);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n.id
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "24px",
              "width": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  })], 1)]], 2), _c('modal', {
    staticClass: "crm-center-modal",
    attrs: {
      "name": `${_vm._uid}-export`
    }
  }, [_c('div', {
    staticClass: "crm-center-modal-header"
  }, [_c('div', {
    staticClass: "crm-center-modal-header-title"
  }, [_vm._v("Export Activities")]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-export`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Select file type")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.export.send,
      "options": [{
        text: 'CSV',
        value: 'csv'
      }, {
        text: 'XLS',
        value: 'xls'
      }, {
        text: 'XLSX',
        value: 'xlsx'
      }]
    },
    model: {
      value: _vm.forms.export.type,
      callback: function ($$v) {
        _vm.$set(_vm.forms.export, "type", $$v);
      },
      expression: "forms.export.type"
    }
  })], 1), _c('b-form-radio-group', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px",
    model: {
      value: _vm.forms.export.period,
      callback: function ($$v) {
        _vm.$set(_vm.forms.export, "period", $$v);
      },
      expression: "forms.export.period"
    }
  }, [_c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    attrs: {
      "value": "today"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Today")])]), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    attrs: {
      "value": "last_7_days"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Last 7 Days")])]), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    attrs: {
      "value": "this_month"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("This Month")])]), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    attrs: {
      "value": "last_month"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Last Month")])]), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    attrs: {
      "value": ""
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("All")])]), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4 crm-squared-radio",
    staticStyle: {
      "border-bottom": "1px solid #DEDEDE"
    },
    attrs: {
      "value": "custom"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Custom")])])], 1), _vm.filters.activities.filter_id ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm.filters.activities.filter_id ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-4"
  }, [_c('b-form-checkbox', {
    staticClass: "crm-checkbox w-100",
    attrs: {
      "disabled": _vm.api.export.send
    },
    model: {
      value: _vm.forms.export.apply_filter,
      callback: function ($$v) {
        _vm.$set(_vm.forms.export, "apply_filter", $$v);
      },
      expression: "forms.export.apply_filter"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Export records based on the applied filters?")])])], 1)] : _vm._e()], 2) : _vm._e(), _vm.forms.export.period == 'custom' ? [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned mt-20px"
  }, [_c('label', [_vm._v("Label")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "disabled": _vm.api.export.send,
      "format": "YYYY-MM-DD",
      "range": true,
      "noHeader": true,
      "noShortcuts": true,
      "noButton": true
    },
    model: {
      value: _vm.forms.export.date_range,
      callback: function ($$v) {
        _vm.$set(_vm.forms.export, "date_range", $$v);
      },
      expression: "forms.export.date_range"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex align-items-center mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthCloseButton w-50 mr-3",
    attrs: {
      "disabled": _vm.api.export.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-export`);
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "fullWidthDoneButton w-50 ml-3",
    attrs: {
      "disabled": _vm.api.export.send
    },
    on: {
      "click": function ($event) {
        return _vm.exportActivities();
      }
    }
  }, [_vm._v("Export")])])]), _c('modal', {
    staticClass: "crm-center-modal",
    attrs: {
      "name": `${_vm._uid}-action`
    }
  }, [_vm.selected.action ? [_c('div', {
    staticClass: "crm-center-modal-header"
  }, [_c('div', {
    staticClass: "crm-center-modal-header-title"
  }, [_vm._v(_vm._s(_vm.selected.action.name))]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-action`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _vm._l(_vm.selected.action.fields, function (field) {
    return _c('div', {
      key: field.attribute
    }, [field.attribute == 'activity_type_id' ? _c('div', {
      staticClass: "latestGreyBox-9-9-2023"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, _vm._l(_vm.activityTypes, function (activity_type) {
      return _c('div', {
        key: activity_type.id,
        staticClass: "newCloseButton onlyIcon mr-2",
        class: {
          active: _vm.selected.activity_type_id == activity_type.id
        },
        on: {
          "click": function ($event) {
            _vm.api.action.send ? '' : _vm.selected.activity_type_id = activity_type.id;
          }
        }
      }, [_c('vb-icon', {
        class: `${activity_type.name == 'Task' ? 'squared-done-icon' : ''}`,
        attrs: {
          "icon": _vm._f("activityTypeIcons")(activity_type),
          "height": "18px",
          "width": "18px"
        }
      })], 1);
    }), 0)]) : _vm._e(), field.attribute == 'user_id' ? _c('div', {
      staticClass: "latestGreyBox-9-9-2023"
    }, [_c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
    }, [_c('vb-select', {
      attrs: {
        "disabled": _vm.api.action.send,
        "options": field.options,
        "textField": "name",
        "valueField": "id"
      },
      model: {
        value: _vm.selected.user_id,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "user_id", $$v);
        },
        expression: "selected.user_id"
      }
    })], 1)]) : _vm._e()]);
  }), _vm.selected.action.fields.length == 0 ? _c('div', [_vm._v(_vm._s(_vm.selected.action.message))]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthCloseButton w-50 mr-10px",
    attrs: {
      "disabled": _vm.api.action.send
    },
    on: {
      "click": function ($event) {
        _vm.selected.action = null;
        _vm.selected.user_id = '';
        _vm.selected.activity_type_id = '';
        _vm.$modal.hide(`${_vm._uid}-action`);
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "fullWidthDoneButton w-50 ml-10px",
    attrs: {
      "disabled": _vm.api.action.send
    },
    on: {
      "click": function ($event) {
        return _vm.action();
      }
    }
  }, [_vm._v("Confirm")])])] : _vm._e()], 2), _c('modal', {
    staticClass: "dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal",
    attrs: {
      "name": `${_vm._uid}-CreateActivity`,
      "width": "50%",
      "scrollable": true,
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "CRM-modal-header"
  }, [_c('div', {
    staticClass: "CRM-modal-header-text"
  }, [_vm._v("Create activity")]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-CreateActivity`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('b-form', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Title")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Title"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mb-16px"
  }, [_c('div', {
    staticClass: "newCloseButton onlyIcon active mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-call-icon",
      "height": "18px",
      "width": "18px"
    }
  })], 1), _c('div', {
    staticClass: "newCloseButton onlyIcon mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-clock-icon",
      "font-scale": "1.0",
      "scale": "1.2"
    }
  })], 1), _c('div', {
    staticClass: "newCloseButton onlyIcon mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-mail-icon",
      "height": "23.11px",
      "width": "16px"
    }
  })], 1), _c('div', {
    staticClass: "newCloseButton onlyIcon mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-multipleUsers-icon",
      "height": "20px",
      "width": "18px"
    }
  })], 1), _c('div', {
    staticClass: "newCloseButton onlyIcon mr-2"
  }, [_c('vb-icon', {
    staticClass: "squared-done-icon",
    attrs: {
      "icon": "squared-done-icon"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "crm-input-container VueCtkDateTimePicker subSectionLeftAligned"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "format": 'YYYY-MM-DD HH:mm',
      "id": "start_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('span', {
    staticClass: "mx-2 mb-16px"
  }, [_vm._v(" - ")]), _c('div', {
    staticClass: "crm-input-container VueCtkDateTimePicker subSectionRightAligned"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "format": 'YYYY-MM-DD HH:mm',
      "id": "end_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "w-100 d-flex align-items-end"
  }, [_c('div', {
    staticClass: "crm-input-container w-25 min-width-250px mr-3"
  }, [_c('label', [_vm._v("Reminder")]), _c('div', {
    staticClass: "crm-input-inner w-100 position-relative"
  }, [_c('b-icon', {
    staticClass: "crm-input-icon",
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  }), _c('b-form-input', {
    attrs: {
      "placeholder": "minutes"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion mr-3 mb-16px"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v("minutes")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" minutes ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" hours ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" days ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" weeks ")])])], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center h-40px mb-16px"
  }, [_c('div', [_vm._v("before due")])])]), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select user")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Select User"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }])
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Description")]), _c('textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Description"
    }
  })]), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Notes")]), _c('textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Notes"
    }
  })]), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Guests")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Guests"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }])
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Deals")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Deals"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }])
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Contacts")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Contacts"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }])
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Companies")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Companies"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }])
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mt-20px",
    staticStyle: {
      "height": "40px",
      "width": "15%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-20px",
    staticStyle: {
      "height": "40px",
      "width": "20%"
    }
  })]);

}]

export { render, staticRenderFns }