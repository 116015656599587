var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DocumentSection"
  }, [_vm.documentsTableView ? [_c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(" Documents ")]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.documentsTableView = !_vm.documentsTableView;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Create Documents ")])], 1)])]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "crm-card halfWidth"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Sent documents by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion mr-3 ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1917268709)
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1)], 1), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1960510485)
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)])]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card halfWidth"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Documents by status "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1960510485)
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)])]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])])]), _c('div', {
    staticClass: "w-100 d-flex align-items-center mb-3"
  }, [_c('button', {
    staticClass: "crm-colored-btn draft mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-file-icon",
      "font-scale": "1.3"
    }
  }), _c('span', {
    staticClass: "crm-colored-btn-textPart"
  }, [_vm._v("Draft")])], 1), _c('button', {
    staticClass: "crm-colored-btn sent mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-submit-icon",
      "font-scale": "1.3"
    }
  }), _c('span', {
    staticClass: "crm-colored-btn-textPart"
  }, [_vm._v("Sent")])], 1), _c('button', {
    staticClass: "crm-colored-btn accepted mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "font-scale": "1.3"
    }
  }), _c('span', {
    staticClass: "crm-colored-btn-textPart"
  }, [_vm._v("Accepted")])], 1), _c('button', {
    staticClass: "crm-colored-btn lost mr-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "font-scale": "1.3"
    }
  }), _c('span', {
    staticClass: "crm-colored-btn-textPart"
  }, [_vm._v("Lost")])], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mb-3"
  }, [_c('div', {
    staticClass: "w-100 d-flex"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('vb-icon', {
          staticClass: "crm-filterOptions-icon",
          attrs: {
            "icon": "crm-filterOptions-icon"
          }
        }), _c('span', [_vm._v("Filter")]), _c('b-icon', {
          staticClass: "ml-2",
          attrs: {
            "icon": "chevron-down"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3589770011)
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1), _c('button', {
    staticClass: "newButton onlyIcon ml-8px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-pencil-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-input-container mb-0 mr-3"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "id": "checkbox-1"
            },
            model: {
              value: data.chk,
              callback: function ($$v) {
                _vm.$set(data, "chk", $$v);
              },
              expression: "data.chk"
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }], null, false, 491608421)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_c('b-form-checkbox', {
    staticClass: "crm-checkbox",
    attrs: {
      "id": "checkbox-1"
    }
  })], 1), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Document Title")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Document Type")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("status")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("owner")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Amount")])])])])], 2)], 1)] : [_c('div', {
    staticClass: "crm-main-section-topBar withArrowTabs"
  }, [_c('div', {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "bold",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.documentsTableView = !_vm.documentsTableView;
      }
    }
  }, [_vm._v("Exit")]), _c('div', {
    staticClass: "ArrowTabs-container"
  }, [_c('div', {
    class: `ArrowTabs-item ${_vm.selectedTab == 'Details' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTab = 'Details';
      }
    }
  }, [_vm._v("Details")]), _c('b-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "chevron-right"
    }
  }), _c('div', {
    class: `ArrowTabs-item ${_vm.selectedTab == 'Products' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTab = 'Products';
      }
    }
  }, [_vm._v("Products")]), _c('b-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "chevron-right"
    }
  }), _c('div', {
    class: `ArrowTabs-item ${_vm.selectedTab == 'Signature' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTab = 'Signature';
      }
    }
  }, [_vm._v("Signature")]), _c('b-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "chevron-right"
    }
  }), _c('div', {
    class: `ArrowTabs-item ${_vm.selectedTab == 'Content' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTab = 'Content';
      }
    }
  }, [_vm._v("Content")]), _c('b-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "chevron-right"
    }
  }), _c('div', {
    class: `ArrowTabs-item ${_vm.selectedTab == 'Send' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTab = 'Send';
      }
    }
  }, [_vm._v("Send")])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "newDoneButton smaller",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Save")])], 1)])]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_vm.selectedTab == 'Details' ? [_c('div', {
    staticClass: "max-width-768px"
  }, [_c('div', {
    staticClass: "underlineTabs-container"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "underlineTabs-header"
  }, [_c('div', {
    class: `underlineTabs-header-item ${_vm.selectedDocumentTab == 'Details' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedDocumentTab = 'Details';
      }
    }
  }, [_vm._v(" Document Details ")]), _c('div', {
    class: `underlineTabs-header-item ${_vm.selectedDocumentTab == 'Activity' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedDocumentTab = 'Activity';
      }
    }
  }, [_vm._v(" Document Activity ")])]), _vm.selectedDocumentTab == 'Details' ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-dropdown onlyTextInButton-dropDown"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v("Associated with no records")])])];
      },
      proxy: true
    }], null, false, 438438129)
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" minutes ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" hours ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" days ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" weeks ")])])], 1)], 1), _c('div', {
    staticClass: "AdmintaggedVersion ml-2 mb-0"
  }, [_vm._v("Proposal")]), _c('div', {
    staticClass: "tagTypeDesign ml-2"
  }, [_vm._v("Draft")])]) : _vm._e()]), _c('div', {
    staticClass: "underlineTabs-content mt-20px"
  }, [_vm.selectedDocumentTab == 'Details' ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "crm-input-container mb-0 CallType w-100"
  }, [_c('label', [_vm._v("Choose a deal")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Companies"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }], null, false, 1612167405)
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2), _c('p', {
    staticClass: "mt-8px mb-0",
    staticStyle: {
      "font-size": "14px",
      "line-height": "1.3"
    }
  }, [_vm._v(" When deal is selected, the deal will be automatically associated with the document, deal contacts will be added as signers, and all deal products will be added to the document. ")])], 1)])]), _c('div', {
    staticClass: "crm-input-container CallType mt-20px"
  }, [_c('label', [_vm._v("Brand")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Companies"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }], null, false, 1612167405)
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Title")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Title"
    }
  })], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Document Type")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Companies"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }], null, false, 1612167405)
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Owner")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Companies"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }], null, false, 1612167405)
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2)], 1), _c('div', {
    staticClass: "crm-input-container CallType mb-0"
  }, [_c('label', [_vm._v("HTML view type")]), _c('div', {
    staticClass: "HTMLViewType-container"
  }, [_c('div', {
    class: `HTMLViewType-item ${_vm.HTMLViewType == 'top' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.HTMLViewType = 'top';
      }
    }
  }, [_c('div', {
    staticClass: "HTMLViewType-heading"
  }, [_vm._v("Navigation Top")]), _c('div', {
    staticClass: "HTMLViewType-text"
  }, [_vm._v("Useful for simple documents that require no navigation via headings.")])]), _c('div', {
    class: `HTMLViewType-item ${_vm.HTMLViewType == 'left' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.HTMLViewType = 'left';
      }
    }
  }, [_c('div', {
    staticClass: "HTMLViewType-heading"
  }, [_vm._v("Navigation Left")]), _c('div', {
    staticClass: "HTMLViewType-text"
  }, [_vm._v("Useful for documents that require navigation via headings (h1).")])]), _c('div', {
    class: `HTMLViewType-item ${_vm.HTMLViewType == 'full' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.HTMLViewType = 'full';
      }
    }
  }, [_c('div', {
    staticClass: "HTMLViewType-heading"
  }, [_vm._v("Navigation Left - Full Width")]), _c('div', {
    staticClass: "HTMLViewType-text"
  }, [_vm._v("The content section has no margin, useful for full width documents with headings (h1).")])])])])]) : _vm._e()])])])] : _vm._e(), _vm.selectedTab == 'Products' ? [_c('div', {
    staticClass: "max-width-1104px"
  }, [_c('h2', {
    staticClass: "crm-main-section-subHeading mb-0"
  }, [_vm._v(" Document Products ")]), _c('b-form-group', {
    staticClass: "crm-inlineRadio mt-20px"
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "radio-group-1",
      "options": _vm.options,
      "name": "radio-options"
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }], null, false, 2210231555)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Product")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("QTY")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Tax")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Discount")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Amount")])])])])], 2)], 1)] : _vm._e(), _vm.selectedTab == 'Signature' ? [_c('div', {
    staticClass: "max-width-768px latestGreyBox-9-9-2023"
  }, [_c('h2', {
    staticClass: "crm-main-section-subHeading mb-0"
  }, [_vm._v(" Signature ")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-unset"
  }, [_c('b-form-checkbox', {
    staticClass: "crm-checkbox w-100"
  }, [_c('div', {
    staticClass: "crm-checkbox-heading"
  }, [_vm._v("No Signature")]), _c('div', {
    staticClass: "crm-checkbox-text"
  }, [_vm._v("This document does not require a signature before acceptance.")])])], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('b-form-checkbox', {
    staticClass: "crm-checkbox w-100"
  }, [_c('div', {
    staticClass: "crm-checkbox-heading"
  }, [_vm._v("Use e-signature")]), _c('div', {
    staticClass: "crm-checkbox-text"
  }, [_vm._v("This document require e-signature before acceptance.")])])], 1)]), _c('h2', {
    staticClass: "crm-main-section-subHeading mb-0 mt-20px"
  }, [_vm._v(" Signature ")]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))])]);
        });
      }
    }], null, false, 1768774269)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("E-Mail Address")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Signed")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span')])])])], 2)], 1)] : _vm._e(), _vm.selectedTab == 'Send' ? [_c('div', {
    staticClass: "max-width-768px"
  }, [_c('h2', {
    staticClass: "crm-main-section-subHeading mb-0"
  }, [_vm._v(" Send the document to following signers ")]), _c('b-form-checkbox', {
    staticClass: "crm-checkbox w-100 mt-20px"
  }, [_c('div', {
    staticClass: "crm-checkbox-text"
  }, [_c('b', [_vm._v("Document")])])]), _c('h2', {
    staticClass: "crm-main-section-subHeading mb-0 mt-20px"
  }, [_vm._v(" Recipients ")]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref4) {
        var start = _ref4.start,
          end = _ref4.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))])]);
        });
      }
    }], null, false, 1768774269)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("E-Mail Address")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Signed")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span')])])])], 2), _c('h2', {
    staticClass: "crm-main-section-subHeading mb-0 mt-20px"
  }, [_vm._v(" Send Document ")]), _c('div', {
    staticClass: "SendDocument-heading mt-20px"
  }, [_vm._v(" Send the document from the following account ")]), _c('div', {
    staticClass: "d-flex align-items-center mt-10px",
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('b-icon', {
    staticStyle: {
      "color": "rgb(245, 158, 11)"
    },
    attrs: {
      "icon": "exclamation-triangle"
    }
  }), _c('div', {
    staticClass: "SendDocument-text"
  }, [_vm._v(" Connect an mail account in order to send documents. ")])], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon mt-20px"
  }, [_c('label', [_vm._v("Message Subject")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Your document is ready"
    }
  })], 1), _vm._m(0), _c('b-form-checkbox', {
    staticClass: "crm-checkbox w-100 mt-20px"
  }, [_c('div', {
    staticClass: "crm-checkbox-text"
  }, [_c('b', [_vm._v("Send later?")])])])], 1)] : _vm._e()], 2)]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-input-container withoutIcon mt-20px"
  }, [_c('label', [_vm._v("Message Subject")]), _c('textarea', {
    staticClass: "w-100"
  })]);

}]

export { render, staticRenderFns }