var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestMonitorDashboardBox-row",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_vm.userDashboard ? [_vm.isLive ? [_c('div', {
    staticClass: "latestMonitorDashboardBox-column one"
  }, [_vm.call.status == 'Up' ? [_vm.call.call_type == 'INCOMMING' ? _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-inboundCall",
      "width": "25.827px",
      "height": "25.833px"
    }
  }) : _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-outboundCall",
      "width": "25.829px",
      "height": "25.829px"
    }
  })] : _vm._e(), _c('Info', {
    attrs: {
      "id": _vm.call.accountcode
    }
  })], 2), _c('div', {
    staticClass: "latestMonitorDashboardBox-column two"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(0)))])]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column three"
  }, [_c('vb-icon', {
    staticClass: "mr-12px",
    attrs: {
      "icon": "devices-webExtension-icon",
      "width": "20.219px",
      "height": "24.997px"
    }
  }), _c('span', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v("Web app")])], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column four"
  }, [_vm.call.accountcode != _vm.getCurrentUser.sip.user ? _c('b-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "latestMonitorDashboardBox-dropdown",
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard,
      "title": _vm.getUserPermissions.monitor_dashboard ? '' : 'Your package does not support this feature',
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "mr-8px"
        }, [_vm._v("Listen in")]), _c('vb-icon', {
          attrs: {
            "width": "20.524px",
            "height": "17.127px",
            "icon": "monitordashboard-listenIn-icon"
          }
        })];
      },
      proxy: true
    }], null, false, 1207764365)
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'listen');
      }
    }
  }, [_vm._v(" Listen ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'listen');
      }
    }
  }, [_vm._v(" Whisper ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'barge');
      }
    }
  }, [_vm._v(" Barge ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column five"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(_vm.call.created_at, '', {
    unix: true,
    formate: 'DD MMM YYYY'
  })) + " ")])])] : [_c('div', {
    staticClass: "latestMonitorDashboardBox-column one"
  }, [_c('Info', {
    attrs: {
      "id": _vm.call.user_accountcode
    }
  })], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column two"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm._f("get_property")(_vm.call, 'duration'))))])]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column three"
  }, [_vm.constextensionAccountcodeInfo ? [_c('vb-icon', {
    staticClass: "mr-12px",
    attrs: {
      "icon": _vm._f("accountType")(_vm.constextensionAccountcodeInfo.type, 'icon'),
      "width": "20.219px",
      "height": "24.997px"
    }
  }), _c('span', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(_vm._s(_vm.constextensionAccountcodeInfo.accountname))])] : _vm._e()], 2), _c('div', {
    staticClass: "latestMonitorDashboardBox-column four"
  }, [_vm.call.soundFile ? [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('button', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('click-play', {
          soundFile: _vm.call.soundFile
        });
      }
    }
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "playSound-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Call recording"
    }
  })], 1), _c('button', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('ai-modal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "ai-brain-icon",
      "width": "26px",
      "height": "24px"
    }
  })], 1)])] : _vm._e()], 2), _c('div', {
    staticClass: "latestMonitorDashboardBox-column five"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(" " + _vm._s(_vm.call.calldate) + " ")])])]] : [_vm.isLive ? [_c('div', {
    staticClass: "latestMonitorDashboardBox-column one"
  }, [_vm.call.type != 'outgoing' ? _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-inboundCall",
      "width": "25.827px",
      "height": "25.833px"
    }
  }) : _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-outboundCall",
      "width": "25.829px",
      "height": "25.829px"
    }
  }), _c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_c('Info', {
    attrs: {
      "id": _vm.call.accountcode,
      "only_name": true
    }
  }), _vm._v(" is in call with "), _c('span', {
    staticClass: "darkerColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.number)))])], 1)], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column two"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.duration)))]), _vm.call.status == 'Ringing' ? _c('div', {
    staticClass: "latestMonitorDashboardBox-text mr-8px"
  }, [_vm._v("Ringing")]) : _vm._e()]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column three"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(" Via "), _c('img', {
    attrs: {
      "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm.call.number))
    }
  }), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm.call.number)) + " ")])]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column four"
  }, [_vm.call.accountcode != _vm.getCurrentUser.sip.user ? _c('b-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "latestMonitorDashboardBox-dropdown",
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard,
      "title": _vm.getUserPermissions.monitor_dashboard ? '' : 'Your package does not support this feature',
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "mr-8px"
        }, [_vm._v("Listen in")]), _c('vb-icon', {
          attrs: {
            "width": "20.524px",
            "height": "17.127px",
            "icon": "monitordashboard-listenIn-icon"
          }
        })];
      },
      proxy: true
    }], null, false, 1207764365)
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'listen');
      }
    }
  }, [_vm._v(" Listen ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'listen');
      }
    }
  }, [_vm._v(" Whisper ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.getUserPermissions.monitor_dashboard || _vm.call.status != 'Up' || _vm.api.calling.send.includes(_vm.call.id)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.calling(_vm.call, 'barge');
      }
    }
  }, [_vm._v(" Barge ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column five"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(_vm.call.created_at, '', {
    unix: true,
    formate: 'DD MMM YYYY'
  })) + " ")])])] : [_c('div', {
    staticClass: "latestMonitorDashboardBox-column one"
  }, [_vm.call.call_type == 'INCOMMING' ? _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-inboundCall",
      "width": "25.827px",
      "height": "25.833px"
    }
  }) : _c('vb-icon', {
    staticClass: "latestMonitorDashboardBox-firstColumn-icon",
    attrs: {
      "icon": "monitorDashboard-table-outboundCall",
      "width": "25.829px",
      "height": "25.829px"
    }
  }), ['OUTGOING'].includes(_vm.call.call_type) ? _c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_c('Info', {
    attrs: {
      "id": _vm.call.accountcode,
      "only_name": true
    }
  }), _vm._v(" was in call with "), _c('span', {
    staticClass: "darkerColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.call, 'calee_accountcode'))))])], 1) : ['BUSY', 'ANSWERED'].includes(_vm.call.disposition) ? _c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_c('Info', {
    attrs: {
      "id": _vm.call.accountcode,
      "only_name": true
    }
  }), _vm._v(" was in call with "), _c('span', {
    staticClass: "darkerColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.src)))])], 1) : _c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_c('Info', {
    attrs: {
      "id": _vm.call.accountcode,
      "only_name": true
    }
  }), _vm._v(" was in call with "), _c('span', {
    staticClass: "darkerColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.dst)))])], 1)], 1), _c('div', {
    staticClass: "latestMonitorDashboardBox-column two"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm._f("get_property")(_vm.call, 'duration'))))])]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column three"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [['OUTGOING'].includes(_vm.call.call_type) ? [['BUSY', 'ANSWERED'].includes(_vm.call.disposition) ? [_vm._v(" Via "), _c('img', {
    attrs: {
      "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm.call.src))
    }
  }), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm.call.src)) + " ")] : [_vm._v(" Via "), _c('img', {
    attrs: {
      "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm.call.dst))
    }
  }), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm.call.dst)) + " ")]] : [_vm._v(" Via "), _c('img', {
    attrs: {
      "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(_vm.call, 'calee_accountcode')))
    }
  }), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.call, 'calee_accountcode'))) + " ")]], 2)]), _c('div', {
    staticClass: "latestMonitorDashboardBox-column four"
  }, [_vm.call.soundFile ? [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('button', {
    staticClass: "monitorDashboardIconsButton mr-8px",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('click-play', {
          soundFile: _vm.call.soundFile
        });
      }
    }
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "playSound-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Call recording"
    }
  })], 1), _c('button', {
    staticClass: "monitorDashboardIconsButton",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('ai-modal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "ai-brain-icon",
      "width": "26px",
      "height": "24px"
    }
  })], 1)])] : _vm._e()], 2), _c('div', {
    staticClass: "latestMonitorDashboardBox-column five"
  }, [_c('div', {
    staticClass: "latestMonitorDashboardBox-text"
  }, [_vm._v(" " + _vm._s(_vm._f("showTime")(_vm.call.date)) + " ")])])]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }