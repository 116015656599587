<template>
  <div class="crm-main-section DocumentSection">
    <template v-if="documentsTableView">
      <div class="crm-main-section-topBar">
        <h2 class="crm-main-section-heading mb-0">
          Documents
        </h2>
        <div class="section-topBar-middlePart">
          <vb-icon icon="crm-inputSearch-icon" />
          <input type="text" placeholder="Search">
        </div>
        <div class="section-topBar-lastPart">
          <!-- <button class="crm-button listGridView-btn centerBtn ml-0">
            <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
          </button> -->
          <button class="newButton" @click="documentsTableView = !documentsTableView">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart position-relative d-flex justify-content-center">
              Create Documents
            </span>
          </button>
        </div>
      </div>
      <div class="crm-main-section-body">
        <div class="d-flex justify-content-between">
          <div class="crm-card halfWidth">
            <div class="crm-card-heading">
              <div>
                Sent documents by day
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              
              <div class="d-flex align-items-center">
                <div class="crm-main-dd dropdownVersion mr-3 ul-width-fit-content withCustomSvgCheck">
                  <b-dropdown class="" id="sound-setting-micro-devices" no-caret text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span>Sales Pipeline</span>
                        <b-icon-chevron-down scale="1.1" class="ml-2"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item >
                      <span> All </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> Sales Pipeline </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
                  <b-dropdown class="" id="sound-setting-micro-devices" no-caret text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span>This week</span>
                        <b-icon-chevron-down scale="1.1" class="ml-2"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item >
                      <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> This week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> This Month </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Month </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card halfWidth">
            <div class="crm-card-heading">
              <div>
                Documents by status
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
                  <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span>This week</span>
                        <b-icon-chevron-down scale="1.1" class="ml-2"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item >
                      <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> This week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> This Month </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Month </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
        </div>
        <div class="w-100 d-flex align-items-center mb-3">
          <button class="crm-colored-btn draft mr-2">
            <vb-icon icon="squared-file-icon" font-scale="1.3"/>
            <span class="crm-colored-btn-textPart">Draft</span>
          </button>
          <button class="crm-colored-btn sent mr-2">
            <vb-icon icon="squared-submit-icon" font-scale="1.3"/>
            <span class="crm-colored-btn-textPart">Sent</span>
          </button>
          <button class="crm-colored-btn accepted mr-2">
            <vb-icon icon="squared-done-icon" font-scale="1.3"/>
            <span class="crm-colored-btn-textPart">Accepted</span>
          </button>
          <button class="crm-colored-btn lost mr-2">
            <vb-icon icon="squared-close-icon" font-scale="1.3"/>
            <span class="crm-colored-btn-textPart">Lost</span>
          </button>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center mb-3">
          <div class="w-100 d-flex">
            <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
              <b-dropdown class="" no-caret  >
                <template #button-content>
                  <span class="d-flex align-items-center p-0">
                    <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                    <span>Filter</span>
                    <b-icon icon="chevron-down" class="ml-2" />
                  </span>
                </template>
                <b-dropdown-item >
                  <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <span> This week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> This Month </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Month </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <button class="newButton onlyIcon ml-8px" >
              <vb-icon icon="squared-pencil-icon" height="38px" width="38px" />
            </button>
          </div>
          <div class="crm-input-container mb-0 mr-3">
            <input placeholder="Search" class="w-100"/>
            <vb-icon icon="crm-inputSearch-icon" />
          </div>
          <!-- <div class="totalsTextOnMain">
            1 document
          </div> -->
        </div>
        <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
          <template slot="header">
            <tr>
              <th>
                <b-form-checkbox class="crm-checkbox" id="checkbox-1"/>
              </th>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Document Title</span>
              </th>
              <th class="dialer-has-sort">
                <span>Document Type</span>
              </th>
              <th class="dialer-has-sort">
                <span>status</span>
              </th>
              <th class="dialer-has-sort">
                <span>owner</span>
              </th>
              <th class="dialer-col-right cursor_pointer">
                <span>Amount</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
              <td>
                <b-form-checkbox class="crm-checkbox" v-model="data.chk" id="checkbox-1"/>
              </td>
              <td class="dialer-row-title">
                <span>{{ data.m1 }}</span>
              </td>
              <td class="dialer-row-title"><span class="tagTypeDesign">{{ data.m1 }}</span></td>
              <td class="dialer-row-title"><span class="tagTypeDesign">{{ data.m1 }}</span></td>
              <td class="dialer-row-title">{{ data.m1 }}</td>
              <td class="dialer-col-right">
                {{data.m2}}
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </template>
    <template v-else>
      <div class="crm-main-section-topBar withArrowTabs">
        <div style="font-size:16px;font-weight: bold;cursor: pointer;" @click="documentsTableView = !documentsTableView">Exit</div>
        <div class="ArrowTabs-container">
          <div @click="selectedTab = 'Details'" :class="`ArrowTabs-item ${selectedTab == 'Details' ? 'active' : ''}`">Details</div>
          <b-icon icon="chevron-right" class="mx-3"/>

          <div @click="selectedTab = 'Products'" :class="`ArrowTabs-item ${selectedTab == 'Products' ? 'active' : ''}`">Products</div>
          <b-icon icon="chevron-right" class="mx-3"/>

          <div @click="selectedTab = 'Signature'" :class="`ArrowTabs-item ${selectedTab == 'Signature' ? 'active' : ''}`">Signature</div>
          <b-icon icon="chevron-right" class="mx-3"/>

          <div @click="selectedTab = 'Content'" :class="`ArrowTabs-item ${selectedTab == 'Content' ? 'active' : ''}`">Content</div>
          <b-icon icon="chevron-right" class="mx-3"/>

          <div @click="selectedTab = 'Send'" :class="`ArrowTabs-item ${selectedTab == 'Send' ? 'active' : ''}`">Send</div>
        </div>
        
        <div class="d-flex">
          <!-- <button class="newButton onlyIcon mr-3">
            <b-icon icon="clipboard" class="crm-threeDotsHorizontal-icon" />
          </button> -->
          <!-- <button class="crm-button">
            Save
          </button> -->
          <button class="newDoneButton smaller" type="button">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Save</span>
          </button>
        </div>
      </div>
      <div class="crm-main-section-body">
        <template v-if="selectedTab == 'Details'">
          <div class="max-width-768px">
            <div class="underlineTabs-container">
              <div class="d-flex align-items-center justify-content-between">
                <div class="underlineTabs-header">
                  <div :class="`underlineTabs-header-item ${selectedDocumentTab == 'Details' ? 'active' : ''}`" @click="selectedDocumentTab = 'Details'">
                    Document Details
                  </div>
                  <div :class="`underlineTabs-header-item ${selectedDocumentTab == 'Activity' ? 'active' : ''}`" @click="selectedDocumentTab = 'Activity'">
                    Document Activity
                  </div>
                </div>
                <div class="d-flex align-items-center" v-if="selectedDocumentTab == 'Details'">
                  <div class="dialer-dropdown onlyTextInButton-dropDown">
                    <b-dropdown no-caret>
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span class="mr-2">Associated with no records</span>
                        </span>
                      </template>
                      <b-dropdown-item >
                        <span> minutes </span>
                      </b-dropdown-item>
                      <b-dropdown-item >
                        <span> hours </span>
                      </b-dropdown-item>
                      <b-dropdown-item >
                        <span> days </span>
                      </b-dropdown-item>
                      <b-dropdown-item >
                        <span> weeks </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="AdmintaggedVersion ml-2 mb-0">Proposal</div>
                  <div class="tagTypeDesign ml-2">Draft</div>
                </div>
              </div>
              <div class="underlineTabs-content mt-20px">
                <div v-if="selectedDocumentTab == 'Details'" class="latestGreyBox-9-9-2023">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="crm-input-container mb-0 CallType w-100">
                        <label>Choose a deal</label>
                        <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Companies">
                          <template slot="no-options">There is no user</template>
                          <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
                          <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
                        </v-select>
                        <p class="mt-8px mb-0" style="font-size: 14px;line-height: 1.3;">
                          When deal is selected, the deal will be automatically associated with the document, deal contacts will be added as signers, and all deal products will be added to the document.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="crm-input-container CallType mt-20px">
                    <label>Brand</label>
                    <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Companies">
                      <template slot="no-options">There is no user</template>
                      <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
                      <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
                    </v-select>
                  </div>
                  <div class="crm-input-container withoutIcon">
                    <label>Title</label>
                    <b-form-input placeholder="Title" />
                  </div>
                  <div class="crm-input-container CallType">
                    <label>Document Type</label>
                    <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Companies">
                      <template slot="no-options">There is no user</template>
                      <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
                      <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
                    </v-select>
                  </div>
                  <div class="crm-input-container CallType">
                    <label>Owner</label>
                    <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Companies">
                      <template slot="no-options">There is no user</template>
                      <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
                      <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
                    </v-select>
                  </div>
                  <div class="crm-input-container CallType mb-0">
                    <label>HTML view type</label>
                    <div class="HTMLViewType-container">
                      <div :class="`HTMLViewType-item ${HTMLViewType == 'top' ? 'active' : ''}`" @click="HTMLViewType = 'top'">
                        <div class="HTMLViewType-heading">Navigation Top</div>
                        <div class="HTMLViewType-text">Useful for simple documents that require no navigation via headings.</div>
                      </div>
                      <div :class="`HTMLViewType-item ${HTMLViewType == 'left' ? 'active' : ''}`" @click="HTMLViewType = 'left'">
                        <div class="HTMLViewType-heading">Navigation Left</div>
                        <div class="HTMLViewType-text">Useful for documents that require navigation via headings (h1).</div>
                      </div>
                      <div :class="`HTMLViewType-item ${HTMLViewType == 'full' ? 'active' : ''}`" @click="HTMLViewType = 'full'">
                        <div class="HTMLViewType-heading">Navigation Left - Full Width</div>
                        <div class="HTMLViewType-text">The content section has no margin, useful for full width documents with headings (h1).</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="selectedTab == 'Products'">
          <div class="max-width-1104px">
            <h2 class="crm-main-section-subHeading mb-0">
              Document Products
            </h2>
            <b-form-group class="crm-inlineRadio mt-20px">
              <b-form-radio-group
                id="radio-group-1"
                v-model="selected"
                :options="options"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
            <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover">
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Product</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>QTY</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Tax</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Discount</span>
                  </th>
                  <th class="dialer-col-right cursor_pointer">
                    <span>Amount</span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <span>{{ data.m1 }}</span>
                  </td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-col-right">
                    {{data.m2}}
                  </td>
                </tr>
              </template>
            </vb-table>
          </div>
        </template>
        <template v-if="selectedTab == 'Signature'">
          <div class="max-width-768px latestGreyBox-9-9-2023">
            <h2 class="crm-main-section-subHeading mb-0">
              Signature
            </h2>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-unset">
                <b-form-checkbox class="crm-checkbox w-100">
                  <div class="crm-checkbox-heading">No Signature</div>
                  <div class="crm-checkbox-text">This document does not require a signature before acceptance.</div>
                </b-form-checkbox>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
                <b-form-checkbox class="crm-checkbox w-100">
                  <div class="crm-checkbox-heading">Use e-signature</div>
                  <div class="crm-checkbox-text">This document require e-signature before acceptance.</div>
                </b-form-checkbox>
              </div>
            </div>
            <h2 class="crm-main-section-subHeading mb-0 mt-20px">
              Signature
            </h2>
            <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover">
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Name</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>E-Mail Address</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Signed</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span></span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <span>{{ data.m1 }}</span>
                  </td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                </tr>
              </template>
            </vb-table>
          </div>
        </template>
        <template v-if="selectedTab == 'Send'">
          <div class="max-width-768px">
            <h2 class="crm-main-section-subHeading mb-0">
              Send the document to following signers
            </h2>
            <b-form-checkbox class="crm-checkbox w-100 mt-20px">
              <div class="crm-checkbox-text">
                <b>Document</b>
              </div>
            </b-form-checkbox>
            <h2 class="crm-main-section-subHeading mb-0 mt-20px">
              Recipients
            </h2>
            <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover">
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Name</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>E-Mail Address</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Signed</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span></span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <span>{{ data.m1 }}</span>
                  </td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                </tr>
              </template>
            </vb-table>
            <h2 class="crm-main-section-subHeading mb-0 mt-20px">
              Send Document
            </h2>
            <div class="SendDocument-heading mt-20px">
              Send the document from the following account
            </div>
            <div class="d-flex align-items-center mt-10px" style="gap: 8px;">
              <b-icon icon="exclamation-triangle" style="color: rgb(245, 158, 11);" />
              <div class="SendDocument-text">
                Connect an mail account in order to send documents.
              </div>
            </div>
            <div class="crm-input-container withoutIcon mt-20px">
              <label>Message Subject</label>
              <b-form-input placeholder="Your document is ready" />
            </div>
            <div class="crm-input-container withoutIcon mt-20px">
              <label>Message Subject</label>
              <textarea class="w-100" />
            </div>
            <b-form-checkbox class="crm-checkbox w-100 mt-20px">
              <div class="crm-checkbox-text">
                <b>Send later?</b>
              </div>
            </b-form-checkbox>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// import axios from 'axios'
// import { VOIP_API } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import { GET_CRM_USER } from '@/store/helper/getters'
// import required from 'vuelidate/lib/validators/required'
// import numeric from 'vuelidate/lib/validators/numeric'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ctkDateTimePicker } from '@/mixin'

export default {
  name: 'Documents',
  mixins: [ctkDateTimePicker],
  components: {
    vSelect,
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      documentsTableView: true,
      selectedTab: 'Details',
      selectedDocumentTab: 'Details',
      HTMLViewType: 'top',
      selected: 'first',
      options: [
        { text: 'Tax Exclusive', value: 'first' },
        { text: 'Tax Inclusive', value: 'second' },
        { text: 'No Tax', value: 'third'},
      ],
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USER,
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>