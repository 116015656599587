<template>
  <div 
    class="callActivity-Item-v2-container"
    :class="[
      call.status === 'Dialed' ? 'Right' : 'Left',
      {
        'visibleTransition': isVisible,
        'missedCall': call.status === 'Missed',
        'recievedCall': call.status === 'Received',
        'dialledCall': call.status === 'Dialed',
        'voicemailMessage': call.call_type === 'voicemail',
        'itsSMS': call.call_type === 'sms',
      }
    ]" 
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        threshold: 1,
      },
    }"
  >
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-b-popover.hover.top="callInfo.text" :icon="callInfo.icon" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <small v-if="call.call_type === 'voicemail' && call.is_shared=='yes'">Shared</small>
        <small v-if="call.is_out_of_hours">Business hour</small>
        <div class="user-name">{{ callInfo.user_name | number_formater }}</div>
        <div v-if="callInfo.user_extn" class="user-number">{{ callInfo.user_extn | number_formater }}</div>
        <div v-if="!$store.getters.getIsMobile" class="via-text">
          <template v-if="call.is_team && call.team_name">
            called <b>{{ call.team_name | number_formater }}</b>
          </template>
          <template v-else-if="call.is_ivr && call.ivr_details && call.ivr_details.accountname">
            called <b>{{ call | get_property('ivr_details.accountname') | number_formater }}</b>
          </template>
          <template v-else-if="call.queue_detail && call.queue_detail.accountname">
            called <b>{{ call | get_property('queue_detail.accountname') | number_formater }}</b>
          </template>
          <template v-else-if="callInfo.is_external && callInfo.via">
            {{ call.status === 'Dialed' ? 'via' : 'called'}} <b>{{ callInfo.via | number_formater }}</b>
          </template>
          <template v-else-if="call.call_type=='sms' && callInfo.via">
            via <b>{{ callInfo.via | number_formater }}</b>
          </template>
          <template v-else-if="call.is_out_of_hours && callInfo.via">
            called <b>{{ callInfo.via | number_formater }}</b>
          </template>
          <template v-else-if="call.destinationDidNumber">
            called <b>{{ call.destinationDidNumber | number_formater }}</b>
          </template>
        </div>
        <!-- <div v-if="call.transcript">{{ call.transcript }}</div> -->
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          <div v-if="condition.recording && call.call_type === 'voicemail' && GET_LOCAL_SETTING_DASHBOARD.design.width != 25 && call.voicemail" class="dialer-audio-playback">
            <vb-audio-player :design="'minimum'" :src="call.voicemail" />
          </div>
        </span>
      </div>
      <div v-if="!(call.call_type == 'sms' && call.sms_type=='custom')" class="callLogIcons-container">
        <template v-if="condition.actions">
          <template v-if="!assignedData && call.call_type !== 'voicemail'">
            <!-- call -->
            <span class="mr-8px">
              <vb-icon v-b-tooltip.hover title="Audio call" icon="call-callLog-icon" width="35px" height="35px" @click="calling()"/>
            </span>
            <!-- video call -->
            <span class="mr-8px" v-if="call.call_type == 'video'">
              <vb-icon v-b-tooltip.hover title="Video call" icon="videoCall-callLog-icon" width="35px" height="35px" 
                v-if="call.call_type == 'video'" @click="calling('video')"
              />
            </span>
          </template>
          <!-- call info -->
          <span class="mr-8px" v-if="!assignedData">
            <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px"
              v-if="!assignedData" @click="$emit('onClickInfo1')"
              v-b-tooltip.hover :title="`${call.call_type === 'voicemail' || call.call_type === 'sms' ? 'Message' : 'Call'} info`"
            />
          </span>
          <!-- add contacts -->
          <span class="mr-8px" v-if="!assignedData && call.is_did && !call.contact_name" >
            <vb-icon v-b-tooltip.hover title="Add to contacts" icon="addContact-callLog-icon" width="35px" height="35px"
              v-if="!assignedData && call.is_did && !call.contact_name" 
              @click="$store.state.global_conditions.add_addressbooks = callInfo.user_name"
            />
          </span>
          <!-- assign task -->
          <span class="mr-8px" v-if="!isTodoAdded && !call.isTaskAssigned && call.call_type=='audio'">
            <vb-icon icon="assignTask-callLog-icon" width="35px" height="35px"
              v-b-tooltip.hover title="Add to tasks"
              v-if="!isTodoAdded && !call.isTaskAssigned && call.call_type=='audio'" @click="addTodoTask()"
            />
          </span>
          <!-- recording -->
          <span v-if="call.soundFile && call.call_type=='audio'">
            <vb-icon @click="$emit('click-play',{ soundFile: call.soundFile })" icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
          </span>
          <!-- transcript modal icon -->
          <!--  && call.transcribe!=0 -->
          <span v-if="call.soundFile && call.call_type=='audio' && call.status!='Missed'">
            <vb-icon class="aiTranscript"  icon="ai-brain-icon" width="20px" height="18px" @click="$emit('openOnlyTranscriptModal')" v-b-tooltip.hover title="AI transcript" />
          </span>
        </template>
      </div>
      <div v-if="!(call.call_type == 'sms' && call.sms_type=='custom')" class="last-section">
        <div class="time">
          <span>{{ call.date | showTime }}</span>
          <b-button v-if="condition.assign" @click="$emit('assign')">
            Assign
          </b-button>
          <slot name="more">
            <b-dropdown v-if="condition.actions" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template v-slot:button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <b-dropdown-item 
                v-if="!assignedData && call.is_did && !call.contact_name" 
                @click="$store.state.global_conditions.add_addressbooks = callInfo.user_name"
              >
                <vb-icon icon="dialer-addToContact-icon" class="dialer-addToContact-icon" width="16.245" height="15.004" />
                <span class="ml-3">Add contact</span>
              </b-dropdown-item>
              <template v-if="!assignedData && call.call_type !== 'voicemail'">
                <b-dropdown-item @click="calling()">
                  <b-icon icon="telephone" font-scale="0.96"/>
                  <span class="ml-3">Call</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="call.call_type == 'video'" @click="calling('video')">
                  <b-icon icon="telephone" font-scale="0.96"/>
                  <span class="ml-3">Video call</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item v-if="!assignedData" @click="$emit('onClickInfo1')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">{{ call.call_type === "voicemail" || call.call_type === "sms" ? "Message" : "Call" }} info</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="assignedData && call.call_type=='audio' && call.status=='Missed'" @click="$emit('clickAssignDetail')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Assigned detail</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item v-if="!assignedData && call.call_type=='audio' && call.status=='Missed' && !isTodoAdded" @click="addTodo()">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Add to TODO</span>
              </b-dropdown-item> -->
              <b-dropdown-item v-if="!isTodoAdded && !call.isTaskAssigned && call.call_type=='audio'" @click="addTodoTask()">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Add to tasks</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
        <div v-if="call.call_type!='sms' && call.call_type!='voicemail'" class="duration">{{ duration | secondsTimer }}</div>
      </div>
    </div>
    <div v-if="call.call_type === 'audio' || call.call_type === 'sms'" class="callActivity-Item-v2-inner-2nd">
      <template v-if="tags_note_show">
        <!-- tag -->
        <div v-if="condition.tag" class="tags-container">
          <div v-for="tag in tags" :key="tag.id" class="dialer-call-prefix tag">
            <small v-vb-tag-color="tag.tag.colour">
              <span v-vb-tag-color.text="tag.tag.text_color">{{ tag.tag.tag }}</span>
            </small>
          </div>
        </div>
        <!-- note -->
        <span v-if="condition.note && note" class="dialer-call-prefix note">{{ note }}</span>
      </template>
      <!-- sms -->
      <span v-if="condition.message && call.call_type === 'sms'" class="dialer-call-prefix messageText">{{ call.body || "" }}</span>
    </div>
    <div v-if="condition.recording && call.call_type === 'voicemail' && GET_LOCAL_SETTING_DASHBOARD.design.width == 25 && call.voicemail" class="callActivity-Item-v2-inner-2nd">
      <div class="dialer-audio-playback">
        <vb-audio-player :src="call.voicemail" />
      </div>
    </div>
    <p class="extraInfoInCallLogs callTranscriptText mb-0 w-100" v-if="call.transcript">
      <span style="font-weight: 800;">
        {{call.call_type === 'voicemail' ? 'Voicemail' : 'Call'}} transcript :
      </span> 
      {{ call.transcript }}
    </p>
    <p v-if="GET_LOCAL_SETTING_DASHBOARD.design.transfer_log" class="extraInfoInCallLogs mb-0 w-100 ">
      <!-- :id="`popover-${call.callerid}`" -->
      <template v-if="callInfo.forward.enabled">
        <!-- forward -->
        <vb-icon 
          icon="callLogs-forward-icon"
          v-b-popover.hover="'Forward'" 
          width="14.61px" height="15.595px"
        />
        <div v-if="callInfo.forward.from && call.forward_from" class="d-flex align-items-center">
          <div>from</div>
          <template v-if="call.forward_from.accounttype == 'RINGGROUP'">
            <div v-if="call.forward_from.accountname">{{ call.forward_from.accountname | number_formater }}</div>
          </template>
          <template v-else>
            <div v-if="callInfo.forward.from.number">{{ callInfo.forward.from.number | number_formater }}</div>
            <Info v-else :id="callInfo.forward.from.accountcode" :only_name="true" />
          </template>
        </div>
        <template v-if="callInfo.forward.to && call.forward_to">
          <span>to</span>
          <template v-if="call.forward_to.accounttype == 'RINGGROUP'">
            <div v-if="call.forward_to.accountname">{{ call.forward_to.accountname | number_formater }}</div>
          </template>
          <template v-else>
            <span v-if="callInfo.forward.to.number" class="extraInfoInCallLogs-active">{{ callInfo.forward.to.number | number_formater }}</span>
            <Info v-else :id="callInfo.forward.to.accountcode" :only_name="true" />
          </template>
        </template>
      </template>
      <template v-if="(call.is_team || call.queue_detail || call.is_ivr || call.is_forward) && call.answer_accountcode" >
        answered by: <Info :id="call.answer_accountcode" :name="call.answer_accountcode | number_formater" :only_name="true" />
        <vb-icon 
          v-if="call.answer_accountcode_details" 
          :icon="call.answer_accountcode_details.accounttype | extensionIcon"
          :class="call.answer_accountcode_details.accounttype | extensionIcon" 
          v-b-popover.hover="call.answer_accountcode_details.accountname || ''" 
          width="25px" 
          height="25px" 
        />
      </template>
      <template v-if="callInfo.transfer.enabled">
        <!-- transfer -->
        <vb-icon 
          icon="callLogs-transfer-icon"
          v-b-popover.hover="'Transfer'" 
          width="16.448px" height="15.595px"
        />
        <template v-if="callInfo.forward.to">
          <span>to</span>
          <span v-if="callInfo.transfer.to.number">{{ callInfo.transfer.to.number | number_formater }}</span>
          <Info v-else :id="callInfo.transfer.to.accountcode" :only_name="true" />
        </template>
        <!-- <div class="d-flex align-items-center">
          <div>from</div>
          <div v-if="callInfo.transfer.from.number">{{ callInfo.transfer.from.number | number_formater }}</div>
          <Info v-else :id="callInfo.transfer.from.accountcode" :only_name="true" />
        </div> -->
      </template>
    </p>
    <p 
      v-if="call.isTaskAssigned" 
      v-b-tooltip 
      :title="`${call.isTaskAssigned.spaces.prefix}#${call.isTaskAssigned.id}`" 
      @click="!assignedData && condition.actions ? $emit('onClickInfo1',{task_modal: true}) : ''"
      class="extraInfoInCallLogs forTasks mt-1 mb-0 w-100"
    >
      <vb-icon 
        icon="callLogs-task-icon"
        v-b-popover.hover="'Task'" 
        width="18.1px" height="18.11px"
        class="mr-2"
      />
      <template>
        <span class="latestBlueColor">{{call.isTaskAssigned.title}}</span>
      </template>
      <span v-if="call.isTaskAssigned.due_date">Due: {{call.isTaskAssigned.due_date}}</span>
      <template>
        <div 
          v-if="call.isTaskAssigned.status" 
          class="categoryText mr-16px ml-4" 
          :style="`background-color: ${call.isTaskAssigned.status.color};`"
        >
          {{call.isTaskAssigned.status.title}}
        </div>
        <div v-if="call.isTaskAssigned.is_important==1" class="categoryText mx-2 px-1" :style="`background-color: #F2F2F2;`">
          <b-icon icon="exclamation-lg" variant="danger" />
        </div>
      </template>
      <template v-if="call.isTaskAssigned.assigned.length!=0">
        <vb-icon icon="callLogs-task-assignedTo-icon" width="11.851px" height="14.227px" />
        Assigned to <Info :id="call.isTaskAssigned.assigned[0].assigned_to" :only_name="true" />
      </template>
    </p>
  </div>
</template>

<script>
import { events, $fn, VOIP_API } from "../../../utils";
import _ from "lodash";
// import { showTime } from '@/filter'
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { number_formater } from '@/filter';
import Info from '@/components/Lists/Info.vue';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
var interval_id; 
export default {
  components: { Info },
  name: "CallActivityItem1",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    tags_note_show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      api_sent: {
        delete: "",
      },
      api: {
        add_todo: this.$helperFunction.apiInstance({}),
      },
      todo_added: false,
      duration_val: "",
      random_id: '',
      isShowMoreNote: false,
      isVisible: false,
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
    callInfo(){
      const { 
        call_type, 
        status, 
        caller, 
        caller_name,
        callee,
        user, 
        sourceName,
        sourceExt,
        destinationName, 
        destinationExt,
        caller_extension_detail, 
        caller_extension_type,
        callee_extension_detail,
        callee_extension_type,
        remote_caller_id,
        dialable,
        contact_name,
        is_transfered,
        transfer_from,
        transfer_to,
        is_forward,
        forward_from,
        forward_to,
        receiver,
        sms_type,
      } = this.call
      let icon = ''
      let text = ''
      let user_name = ''
      let user_extn = ''
      let dial = ''
      let via = ''
      let is_external = false
      let transfer = {
        enabled: false,
        to: {
          number: '',
          accountcode: '',
        },
        from: {
          number: '',
          accountcode: '',
        },
      }
      let forward = {
        enabled: false,
        to: {
          number: '',
          accountcode: '',
        },
        from: {
          number: '',
          accountcode: '',
        },
      }
      if(call_type=='audio'){
        if(status=='Missed') {
          icon = 'CallActivityV2-missedCall-icon'
          text = 'Missed Audio Call'
          user_name = sourceName || ''
          user_extn = sourceExt || ''
        } else if(status=='Received') {
          icon = 'CallActivityV2-receivedCall-icon'
          text = 'Received Audio Call'
          user_name = sourceName || ''
          user_extn = sourceExt || ''
        } else {
          icon = 'CallActivityV2-dialledCall-icon'
          text = 'Dialed Audio Call'
          user_name = destinationName || ''
          user_extn = destinationExt || ''
        }
        if(!caller_extension_type || !callee_extension_type) {
          is_external = true
        }
        if(remote_caller_id){
          via = remote_caller_id
        } else if(!caller_extension_type && typeof callee_extension_detail == 'string') {
          via = callee_extension_detail
        } else if(!callee_extension_type && typeof caller_extension_detail == 'string') {
          via = caller_extension_detail
        }
      } else if(call_type=='video'){
        user_name = destinationName || ''
        user_extn = destinationExt || ''
        dial = dialable
        if(status=='Missed') {
          icon = 'CallActivityV2-missedVideoCall-icon'
          text = 'Missed Video Call'
        } else if(status=='Received') {
          icon = 'CallActivityV2-receivedVideoCall-icon'
          text = 'Received Video Call'
        } else {
          icon = 'CallActivityV2-dialledVideoCall-icon'
          text = 'Dialed Video Call'
        }
      } else if(call_type=='voicemail'){
        if(caller_name=='-') {
          user_name = caller?.replace?.(/"/g, "") ?? ''
          user_extn = callee?.replace?.(/"/g, "") ?? ''
        } else {
          user_name = caller_name ?? ''
          user_extn = caller?.replace?.(/"/g, "") ?? ''
        }
        icon = 'CallActivityV2-audioMessage-icon'
        text = 'Voicemail'
      } else if(call_type=='sms'){
        icon = 'CallActivityV2-message-icon'
        text = 'SMS'
        if(sms_type=='custom') {
          user_name = user ?? ''
          // via = user ?? ''
        } else if(sms_type=='inbound') {
          user_name = receiver ?? ''
          via = user ?? ''
        } else {
          user_name = receiver ?? ''
          via = user ?? ''
        }
      }
      if(!dial) {
        dial = user_extn || user_name
      }
      if(is_transfered) {
        transfer.enabled=true
        if(typeof transfer_to == 'string') {
          transfer.to.number=transfer_to
        } else {
          transfer.to.accountcode=transfer_to?.accountcode ?? ''
        }
        if(typeof transfer_from == 'string') {
          transfer.from.number=transfer_from
        } else {
          transfer.from.accountcode=transfer_from?.accountcode ?? ''
        }
      }
      if(is_forward) {
        forward.enabled=true
        if(typeof forward_to == 'string') {
          forward.to.number=forward_to
        } else {
          forward.to.accountcode=forward_to?.accountcode ?? ''
        }
        if(typeof forward_from == 'string') {
          forward.from.number=forward_from
        } else {
          forward.from.accountcode=forward_from?.accountcode ?? ''
        }
      }
      return {
        icon,
        text,
        user_name: contact_name || user_name,
        user_extn,
        dialable: dial,
        via,
        is_external,
        transfer: transfer,
        forward: forward,
      }
    },
    isInternalCall(){
      return this.call.dialable?.length==4
    },
    duration() {
      if (this.isTimer) {
        return this.duration_val;
      } else {
        if(this.call.call_type=='audio' && this.call.status=='Missed') return '00:00:00'
        return this.call.billSec;
      }
    },
    isTimer() {
      return this.$store.state.call_activity_timer[this.call.uniqueid];
    },
    note() {
      if (!this.call.notes) return "";
      const [note] = this.call.notes.filter((item) => item.log !== null && item.type === "note");
      return note ? note.log : "";
    },
    tags() {
      // return this.call.notes?.filter?.((item) => item.type=="tag" && !!item.tag) ?? []
      let allTags = this.call.notes?.filter?.((item) => item.type=="tag" && !!item.tag) ?? []
      let filteredTags = []
      allTags.forEach(item => {
        if(item.tag?.deletedtags<1){
          filteredTags.push(item)
        }
      });
      // console.log("filteredTags",filteredTags)
      // return allTags
      return filteredTags
    },
    tagsId() {
      if (!this.call.notes) return [];
      return this.call.notes.filter((item) => item.type=="tag" && !!item.tag).map(tag=>tag.tag.id);
    },
    user() {
      if (this.call.dialable) {
        return this.$store.getters.getVoipUsersAlises[this.call.dialable];
      } else {
        return {};
      }
    },
    callID() {
      return this.call?.callerid;
    },
    condition(){
      return {
        tag: _.isUndefined(_.get(this.conditions,'tag')) ? true : _.get(this.conditions,'tag'),
        note: _.isUndefined(_.get(this.conditions,'note')) ? true : _.get(this.conditions,'note'),
        recording: _.isUndefined(_.get(this.conditions,'recording')) ? true : _.get(this.conditions,'recording'),
        message: _.isUndefined(_.get(this.conditions,'message')) ? true : _.get(this.conditions,'message'),
        assign: _.isUndefined(_.get(this.conditions,'assign')) ? false : _.get(this.conditions,'assign'),
        actions: _.isUndefined(_.get(this.conditions,'actions')) ? true : _.get(this.conditions,'actions'),
      }
    },
    isTodoAdded(){ return this.todo_added },
  },
  filters: {
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      else if(value=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(value=='EXTERNALEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
    tagTextColor(hexcolor){
      // console.log(hexcolor)
      if(!hexcolor) return '#ffffff'
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000000' : '#ffffff';
    },
    secondsTimer(value) {
      if (typeof value === "undefined") return '00:00:00';
      if (typeof value === "string") return value;
      let sec = value % 60;
      let min = Math.floor(value / 60) % 60;
      let hours = Math.floor(value / 60 / 60) % 60;
      return `${hours < 10 ? "0" + hours : hours}:${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
    },
    showTime(value,dv='-'){
      if (!value) return dv;
      return moment.unix(value).format("h:mm a");
    },
    formatDate(value) {
      if (!value) return "-";
      var ts = new Date(value);
      return ts.toDateString();
    },
  },
  watch: {
    isTimer: {
      immediate: true,
      handler(obj) {
        if (!obj) return;
        const { init_time, end_time } = obj;
        let vm = this;
        const created = new Date(init_time);
        const now = new Date();
        let difMilli = now - created;
        vm.duration_val = Math.ceil(difMilli / 1000);
        if (end_time) return;
        interval_id = setInterval(() => {
          if (
            !this.$store.state.call_activity_timer[this.call.uniqueid] ||
            this.$store.state.call_activity_timer[this.call.uniqueid].end_time
          ) {
            clearInterval(interval_id);
          } else {
            const value = difMilli / 1000;
            vm.duration_val = Math.ceil(value);
            difMilli += 1000;
          }
        }, 1000);
      },
    },
  },
  methods: {
    async addTodo(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        await VOIP_API.endpoints.todos.addTodo({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          cdr_id: this.callID,
        })
        this.todo_added=true
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
    async addTodoTask(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.call.status=='Missed') type='missed'
        else if(this.call.status=='Received') type='incoming'
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: number_formater(this.callInfo.user_name),
          cdr_id: this.callID,
          type: type
        })
        this.todo_added=true
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
    calling(calling_type) {
      const { call_type } = this.call;
      const { dialable } = this.callInfo
      if (call_type=='video') {
        const accounts = typeof dialable === "string" ? [dialable] : dialable;
        this.$root.$emit(events.video_call, { 
          accounts, 
          call_type: calling_type ?? 'audio' 
        });
      } else {
        const number = number_formater(dialable)
        this.$root.$emit(events.audio_call, { 
          number: number 
        });
      }
    },
    deleteCallActivity(call) {
      let vm = this;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this voice mail`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.delete = call.id;
            VOIP_API.endpoints.voicemail
              .delete({
                id: call.id,
              })
              .then(() => {
                this.$store.state.common.call_activity.data =
                  this.$store.state.common.call_activity.data.filter(
                    (item) =>
                      `${item.call_type}_${item.id}` !==
                      `${call.call_type}_${call.id}`
                  );
                vm.appNotify({
                  message: "Successfully Deleted!",
                  type: "success",
                })
              })
              .catch((error) => {
                vm.appNotify({
                  message:
                    _.get(error, "response.data.message") ||
                    "Something went wrong",
                  type: "danger",
                })
              })
              .finally(() => {
                vm.api_sent.delete = false;
              });
          }
        },
      });
    },
    onClickInfo() {
      this.$emit("onClickInfo");
    },
    sms(call) {
      this.$root.$emit(events.send_sms, { dialable: call.dialable });
    },
    visibilityChanged (isVisible) { // , entry
      this.isVisible = isVisible
    }
  },
  mounted(){
    this.random_id = $fn.makeid(10)
  },
};
</script>

<style>
</style>