var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.condition.pagination.show ? _c('div', {
    staticClass: "dialer-paginationRow"
  }, [!_vm.isListEmpty && _vm.listLength > _vm.pagination.per_page ? _c('div', {
    staticClass: "dialer-pagination"
  }, [_vm.condition.pagination.first ? _c('div', {
    staticClass: "icon previous-icon fastPrevious-icon",
    attrs: {
      "disabled": _vm.pagination.page === 1
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page = 1;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  })], 1) : _vm._e(), _vm.condition.pagination.prev ? _c('div', {
    staticClass: "icon previous-icon",
    attrs: {
      "disabled": _vm.pagination.page === 1
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page - 1 !== 0 ? _vm.pagination.page = _vm.pagination.page - 1 : '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  })], 1) : _vm._e(), _vm._l(_vm.totalArray, function (n, index) {
    return _c('div', {
      key: index,
      class: `icon ${n == _vm.pagination.page ? 'active' : ''} ${n == '...' ? 'dotted-pagination' : ''}`,
      on: {
        "click": function ($event) {
          n != '...' ? _vm.pagination.page = n : '';
        }
      }
    }, [_vm._v(_vm._s(n))]);
  }), _vm.condition.pagination.next ? _c('div', {
    staticClass: "icon forward-icon",
    attrs: {
      "disabled": _vm.pagination.page === _vm.total
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page + 1 > _vm.total ? '' : _vm.pagination.page = _vm.pagination.page + 1;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  })], 1) : _vm._e(), _vm.condition.pagination.last ? _c('div', {
    staticClass: "icon forward-icon fastForward-icon",
    attrs: {
      "disabled": _vm.pagination.page === _vm.total
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page = _vm.total;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "dialer-arrowDown-icon",
      "width": "13.825",
      "height": "8.326"
    }
  })], 1) : _vm._e()], 2) : _vm._e(), _vm.condition.pagination.per_page && !_vm.isListEmpty && !_vm.isEmpty(_vm.numberListOptions) ? _c('div', {
    staticClass: "dialer-recordPerList"
  }, [_c('span', {
    staticClass: "md-mar-right"
  }, [_vm._v("Result per page")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion whiteBg-select paginationC simple-image"
  }, [_c('vb-select', {
    staticClass: "dialer-dropDown-onRight",
    attrs: {
      "selected": _vm.pagination.per_page,
      "options": _vm.numberListOptions
    },
    on: {
      "change": function ($event) {
        _vm.pagination.per_page = $event;
      }
    }
  })], 1)]) : _vm._e()]) : _c('div');

}
var staticRenderFns = []

export { render, staticRenderFns }