var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Security "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm._m(0), _c('b-form-checkbox', {
    ref: "something",
    staticClass: "crm-switch",
    attrs: {
      "name": "check-button",
      "switch": ""
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm._m(1), _c('b-form-checkbox', {
    ref: "something",
    staticClass: "crm-switch",
    attrs: {
      "name": "check-button",
      "switch": ""
    }
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column wd-70"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Disable password forgot feature")]), _c('p', {
    staticClass: "crm-card-text w-100 mb-0"
  }, [_vm._v(" When enabled, the password forgot feature will be disabled. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column wd-70"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Block bad visitors")]), _c('p', {
    staticClass: "crm-card-text w-100 mb-0"
  }, [_vm._v(" If enabled, a list of bad user agents, ip addresses and referrers will be checked for each guest visitor. ")])]);

}]

export { render, staticRenderFns }