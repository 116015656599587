var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticClass: "three"
  }, [_c('span', {
    staticClass: "display-responsive mr-2"
  }, [_vm._v("TEST :")]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm.data.status === 'Received Call' ? _c('div', [_vm.data.dstExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.dstExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.dstName)))]), _vm._v(" received call from "), _vm.data.srcExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.srcExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.srcName)))])], 1) : _vm.data.status === 'Missed Call' ? _c('div', [_vm.data.srcExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.srcExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.srcName)))]), _vm._v(" call missed from "), _vm.data.dstExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.dstExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.dstName)))])], 1) : _c('div', [_vm.data.srcExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.srcExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.srcName)))]), _vm._v(" called "), _vm.data.dstExt ? _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.data.dstExt
    }
  }) : _vm._e(), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.dstName)))])], 1)])]), _c('td', {
    staticClass: "wd-75 four"
  }, [_c('span', {
    staticClass: "display-responsive mr-2"
  }, [_vm._v("TIME & DURATION :")]), _c('span', {
    staticClass: "time block"
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" " + _vm._s(_vm._f("filterTimeAndDate")(_vm.data.date)) + " ")])]), _c('td', {
    staticClass: "wd-33-33 five"
  }, [_c('span', {
    staticClass: "display-responsive mr-2"
  }, [_vm._v("Ring Time :")]), _c('span', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: `${_vm.data.ringSec}`,
      expression: "`${data.ringSec}`",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "id": `RingTime-${_vm.index}`,
      "data-tooltip": 'Duration: ' + _vm.data.duration
    }
  }, [_c('span', {
    staticClass: "hour"
  }, [_c('i', {
    staticClass: "fas fa-hourglass-start"
  }), _vm._v(" " + _vm._s(_vm.data.billSec) + " ")])])]), true ? _c('td', {
    staticClass: "call_history analyticsReportAudio wd-100 six"
  }, [_c('span', {
    staticClass: "display-responsive mr-2"
  }, [_vm._v("Call Recorded :")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_vm.data.soundFile ? [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: ['d-flex align-items-center', !_vm.getUserPermissions.call_recording ? 'for-disable' : ''],
    attrs: {
      "id": `ReportingCallRecording-${_vm.index}`,
      "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-audio-player', {
    class: !_vm.getUserPermissions.call_recording ? 'for-disable' : '',
    attrs: {
      "design": 'minimum',
      "disabled": !_vm.getUserPermissions.call_recording,
      "src": _vm.data.soundFile
    }
  }), _c('vb-icon', {
    staticClass: "download_icon ml-3",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "callAnalytics-reporting-download-icon",
      "height": "17px",
      "width": "17px",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_recording ? '' : _vm.download();
      }
    }
  }), _c('vb-icon', {
    staticClass: "ml-3",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "ai-brain-icon",
      "width": "18.184px",
      "height": "17.403px"
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_recording ? '' : _vm.$emit('transcript-click');
      }
    }
  })], 1)] : _vm._e()], 2)]) : _c('td', [_vm._v("-")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }