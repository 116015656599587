import { VOIP_API } from "../index"

export const AMI_API_ENDPOINTS = {
  authenticate(data={}){
    return VOIP_API.axios.voip.request({
      url: `ami-token`,
      method: "POST",
      data,
    })
  },
  getBLFStatuses(params={}){
    return VOIP_API.axios.voip.request({
      url: `get-blf-status`,
      method: "GET",
      params,
    })
  },
  getUserActiveState(params={}){
    return VOIP_API.axios.voip.request({
      url: `check-user-online-status`,
      method: "GET",
      params,
    })
  },
  getUserLoginDevices(params={}){
    return VOIP_API.axios.voip.request({
      url: `user-extension-status`,
      method: "GET",
      params,
    })
  },
  getIdleStates(params={}){
    return VOIP_API.axios.voip.request({ 
      url: `user-idle-state`,
      method: "GET",
      params,
    })
  },
  generateToken(data){
    return VOIP_API.axios.ami_server.request({
      url: "generate-token",
      method: "POST",
      data,
    })
  },
  getCallsByUid(params){
    return VOIP_API.axios.voip.request({
      url: "get-calls-status",
      method: "GET",
      params,
    })
  },
}